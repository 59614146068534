import React, { useEffect, useState } from 'react'
import { PlaylistDataType, ProfileType, SongDataType } from '../../types';
import { useLocation } from 'react-router-dom';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { useStore } from '../../store';
import CustomList from './CustomList';
import BackHeader from './BackHeader';
import CustomImage from './CustomImage';
import config from '../../config';

export default function UserProfile() {
  const location = useLocation();
  const userId = location.state.userId;

  const tags = ['My Songs','Playlist'];

  const {store} = useStore();
  const [data, setData] = useState<SongDataType | PlaylistDataType | null>(null);
  const [loading,setLoading] = useState(false);
  const [hasMore,setHasMore] = useState(true);
  const [page,setPage] = useState(1);
  const [selectedTag, setSelectedTag] = useState("My Songs");
  const [profile,setProfile] = useState<ProfileType>();

  const getProfile = async () => {
    const result : any= await api.get(endpoints.user.getProfile + "?userId=" + userId);
    if(result.code !== '200') return;
    setProfile(result.data as ProfileType);
  }

  const fetchData = async () => {
    let dynamicApi;
    if(selectedTag === "Playlist"){
      dynamicApi = endpoints.playlist.getPlaylist;
    }else{
      dynamicApi = endpoints.song.getChordsBy;
    }
    const result : any= await api.get(dynamicApi + `?keyword=&current=${page}&limit=10&userId=${userId}`);

    if(result.code === '200'){
      const d : any = result.data;
      setData((pre) => {
        let obj: any = {...pre};
        let arr : any;
        if(page > 1){
          if(pre && d) arr = [...pre?.by,...d?.by]
          obj.by = arr;
          return obj
        }else{
          return d
        }
      });
    }else{
      if(page > 1 && result.code === '404'){
        setHasMore(false);
      }else{
        setHasMore(false);
        setData(null);
      }
    }
  }

  const handleTagChange = (v: string) => {
    setSelectedTag(v);
    setPage(1);
    setHasMore(true);
  }

  const handleFetchMore = () => {
    setPage(pre => pre + 1);
  }

  useEffect(() => {
    fetchData();
  },[page, selectedTag])

  useEffect(() => {
    if(data !== null && data?.by?.length === data?.pagination?.total) {
      setHasMore(false);
    };
  },[data])

  useEffect(()=> {
    getProfile();
  },[])

  const coverPhotoWidth = window.innerWidth > Number(config.screenWidth.split('px')[0]) ? config.screenWidth  : "100vw";

  const customListHeight = profile?.bio ? store.screenHeight - 390 : store.screenHeight - 340;

  let type: any;
  if(selectedTag === "My Songs"){
    type = "Song"
  }else if(selectedTag === "Playlist"){
    type = "Playlist"
  }

  console.log("type",type,selectedTag, data)

  return (
    <div className="remove-scrollbar" >
      <div style={{ top: "10px", padding: "20px 0", position: 'absolute', width: "100%"}}>
        <div style={{display: "flex", top: "40px",justifyContent: "space-between",alignItems: "center",padding: "0 17px"}}>
          <BackHeader/>
        </div>
      </div>
      <div style={{position: 'absolute',width: coverPhotoWidth}}>
          {
            profile?.coverPhoto ?
            <img style={{height: "157px", width: "100%", objectFit: "cover"}} src={profile?.coverPhoto}/>
            :
            <div  style={{height: "157px", width: "100%",backgroundColor: "black"}}></div>
          }
      </div>
      <div style={{position: "absolute",width: "100%",zIndex: 1000,top: "130px"}}>
        <div style={{padding: "0 17px", display: "flex",justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{width: "100px",height: "100px",borderRadius: "50px",border: "1px solid white"}}>
              <CustomImage src={profile?.avatar || ""} width={'100px'} height={'100px'} borderRadius='50px'/>
            </div>
            <h1 style={{color: "white",fontSize: "20px"}}>{profile?.name}</h1>
          </div>
        </div>
      </div>
      <div style={{padding: "0 17px"}}>
          <div style={{height: "270px"}}>
      
          </div>
          {profile?.bio && <h2 style={{fontSize: "15px",color: "#BFBFBF"}}>{profile?.bio}</h2>}
          <div style={{display: "flex"}}>
            <h2 style={{color: 'white',fontSize: "15px",marginRight: "10px"}}>{profile?.songCount || 0} <span style={{color: '#747474'}}>songs</span></h2>
      </div>
      </div>
      <CustomList
        data={data}
        tags={tags}
        type={type}
        selectedTag={selectedTag}
        onTagSelect={handleTagChange}
        showTagBar
        loading={loading}
        handleFetchMore={handleFetchMore}
        hasMore={hasMore}
        scrollHeight={customListHeight}
        showVersions={false}
      />
    </div>
  )
}
