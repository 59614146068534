import { FacebookLogin } from '@capacitor-community/facebook-login';
import config from '../config';

export class FacebookService {
    private static instance: FacebookService;

    constructor() {
        if (FacebookService.instance) {
            return FacebookService.instance;
        }
        this.init();
        FacebookService.instance = this;
    }

    private init() {
        FacebookLogin.initialize({ appId: config.facebook.appId });
    }

    async login() {
        console.log("hello")
        const FACEBOOK_PERMISSIONS = [
            'email',
        ];
        const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
        console.log('result',result);
          
        if (result.accessToken) {
          return result.accessToken.token
        }else{
            return null;
        }
    }

    logout(){
        FacebookLogin.logout();
    }
}