/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { Input } from 'antd';

const { TextArea } = Input;

interface CustomTextAreaProps {
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  style?: React.CSSProperties;
  upperText?: string
  autoSize?: { minRows: number, maxRows: number}
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({ placeholder, value, onChange, style, upperText, autoSize }) => {
  return (
    <div css={css`
        textarea::placeholder{
          color: gray !important;
        }
    `}>
      <span style={{fontSize: "13px",color: "#5B5B5B",paddingBottom: "5px",display: "inline-block"}}>{upperText}</span>
      <TextArea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoSize={{ minRows: 3, maxRows: 5,...autoSize }}
        spellCheck={false}
        style={{ ...styles.textArea, ...style }}
      />
    </div>
  );
};

const styles = {
  textArea: {
    backgroundColor: '#1A1A1A',
    color: 'white',
    border: '1px solid #333',
    borderRadius: '4px',
    padding: '8px',
    paddingLeft: "20px",
    fontSize: '16px',
    resize: 'none' as const, // Ensures the text area can't be resized
  } as React.CSSProperties,
};

export default CustomTextArea;
