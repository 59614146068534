/** @jsxImportSource @emotion/react */
import React, { ReactNode, useEffect, useRef, UIEventHandler } from 'react';
import { css } from '@emotion/react';
import InfiniteScroll from 'react-infinite-scroll-component';

type InfiniteScrollWrapperProps = {
  height: number;
  children: ReactNode;
  dataLength: number;
  fetchMoreData: () => void;
  hasMore: boolean;
  loader: ReactNode;
  endMessage?: ReactNode;
  handleScroll?: any;
  scrollPos?: number
};

const InfiniteScrollWrapper: React.FC<InfiniteScrollWrapperProps> = ({
  height,
  children,
  dataLength,
  fetchMoreData,
  hasMore,
  loader,
  endMessage,
  handleScroll,
  scrollPos
}) => {

  return (
    <div
      className="scrollable-y remove-scrollbar"
    >
      <InfiniteScroll
        onScroll={handleScroll}
        dataLength={dataLength} // Important field to render the next data
        next={fetchMoreData}
        hasMore={hasMore}
        loader={loader}
        height={height}
        endMessage={endMessage || <p style={{ textAlign: 'center', color: 'white' }}><b>. . .</b></p>}
        initialScrollY={scrollPos}
      >
        {children}
      </InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollWrapper;
