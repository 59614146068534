import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface RouteTrackerProps {
  previousLocation: string | null;
  currentLocation: string;
}

const RouteTracker = createContext<RouteTrackerProps | undefined>(undefined);

export const RouteTrackerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState<string | null>(null);
  const [currentLocation, setCurrentLocation] = useState<string>(location.pathname);

  useEffect(() => {
    setPreviousLocation(currentLocation);
    setCurrentLocation(location.pathname);
  }, [location]);

  return (
    <RouteTracker.Provider value={{ previousLocation, currentLocation }}>
      {children}
    </RouteTracker.Provider>
  );
};

export const useRouteTracker = () => {
  const context = useContext(RouteTracker);
  if (!context) {
    throw new Error('useRouteTracker must be used within a LocationProvider');
  }
  return context;
};
