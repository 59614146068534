import { PushNotifications } from '@capacitor/push-notifications';
import { AndroidSettings, NativeSettings } from 'capacitor-native-settings';

import api from '../api/apiClient';
import { endpoints } from '../api/endpoints';

export class NotiService {

    private notiPermissionStatus: string | null = null;
    private static instance: NotiService;

    private eventCb?: (event: string, data: any) => void;

    constructor(eventCb?: (event: string, data: any) => void) { 
        if (NotiService.instance) {
            return NotiService.instance;
        }
        NotiService.instance = this;
        this.eventCb = eventCb;

        // this.createDefaultChannels(); 
        PushNotifications.addListener('registration', async (token) => {
            console.info('Registration token: ', token.value);
            this.emitCb('registration', { token: token.value });

            const notiToken = token.value;
            const localToken = localStorage.getItem('notiToken');
            if(!localToken || localToken !== notiToken) {
                localStorage.setItem("notiToken", notiToken);
                await this.sendNotiToken(notiToken);
                this.subscribeToTopic("announcement");
                console.info("channels: ", await PushNotifications.listChannels());
            }
        });
    
        PushNotifications.addListener('registrationError', err => {
            console.error('Registration error: ', err.error);
            this.emitCb('registration', { error: err.error });
        });
    
        PushNotifications.addListener('pushNotificationReceived', notification => {
            console.log('Push notification received: ', notification);
            this.emitCb('pushNotificationReceived', notification);
        });
    
        PushNotifications.addListener('pushNotificationActionPerformed', notification => {
            this.emitCb('pushNotificationActionPerformed', notification);
        });
    }   

    async init(){

        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt' || permStatus.receive === 'prompt-with-rationale') {
            permStatus = await PushNotifications.requestPermissions();
        }
        this.notiPermissionStatus = permStatus.receive;

        if (permStatus.receive !== 'granted') return;

        PushNotifications.register();

        console.log("delivered: ", await PushNotifications.getDeliveredNotifications())
        
    }

    private emitCb(event: string, data: any) {
        if (this.eventCb) {
            this.eventCb(event, data);
        }
    }

    createDefaultChannels() {
        PushNotifications.createChannel({
            id : '1',
            name: 'vibrate',
            visibility: 1,
            vibration: true
        });
        PushNotifications.createChannel({
            id : '2',
            name: 'not vibrate',
            visibility: 0,
            vibration: false
        });
    }

    removeAllNotiListener () {
        PushNotifications.removeAllListeners();
    }

    getNotiPermStatus (){
        return this.notiPermissionStatus;
    }

    goToNotiSetting () {
        NativeSettings.openAndroid({
            option: AndroidSettings.AppNotification,
        });
    }

    async sendNotiToken (notiToken: string) {
        let param = {
            notiToken
		};
        const result : any = await api.put(endpoints.pushNoti.sendNotiToken, param)
        if(result.code !== "200") console.warn("send noti token failed: ", result.message)
    }

    async subscribeToTopic (topic: string) {
        let param = {
            topic
		};
        const result : any = await api.put(endpoints.pushNoti.subscribeToTopic, param)
        if(result.code !== "200") console.warn("subscribe to topic failed: ", result.message)
    }

    async unsubscribeToTopic (topic: string) {
        let param = {
            topic
		};
        const result : any = await api.put(endpoints.pushNoti.unsubscribeToTopic, param)
        if(result.code !== "200") console.warn("unsubscribe to topic failed: ", result.message)
    }
}