import { ButtonBase, ButtonBaseProps } from '@mui/material';
import { ButtonProps } from 'antd';
import React from 'react'
import Loader from './Loader';

type CustomButtonProps = ButtonBaseProps & {
    active: boolean;
    loading?: boolean;
}

export default function CustomButton({ active, loading = false, children, style, ...rest }: CustomButtonProps) {
    const btnStyle = {
        color: 'white', 
        border: 'none', 
        borderRadius: '20px',
        padding: '10px 54px',
        margin: '10px 0',
        cursor: 'pointer',
        fontWeight: 'bold',
        ...style, // Merge parent-provided styles
    };

    return (
      <ButtonBase style={{backgroundColor: active ? '#007bff' : "#424242", ...btnStyle}} {...rest}>
        {loading ? <Loader btn /> : children}
      </ButtonBase>
    )
}
