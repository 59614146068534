import axios, { AxiosInstance, AxiosResponse } from 'axios';
import config from '../config';

const api: AxiosInstance = axios.create({
  baseURL: config.backendApi,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use((response) => {
  if (response.data.code === '403' ) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return response.data;
}, (error) => {
  return {
    code: "500",
    data: [],
    message: "Server Error",
  };
});

export default api;
