import React from 'react'
import { Drawer } from 'vaul';
import config from '../../config';

type CustomDrawerProps = {
    isOpen: boolean,
    onClose: () => void,
    children: React.ReactNode,
    outsideInteraction?: boolean
}

const CustomDrawer : React.FC<CustomDrawerProps> = ({children, isOpen, onClose, outsideInteraction = true}) => {
  return (
        <Drawer.Root open={isOpen} onClose={onClose} modal={!outsideInteraction} >
            <Drawer.Portal>
              <Drawer.Overlay/>
              <Drawer.Content
               style={{
                width: config.screenWidth,
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#272727',
                position: 'fixed',
                bottom: "0",
                left: `calc(50% - ${Number(config.screenWidth.split('px')[0]) / 2}px)`,
                border: '10px',
                outline: 'none',
                borderRadius: "50px 50px 0 0 ",
                zIndex: 2000
               }}
              >
                <Drawer.Handle style={{backgroundColor: "white",border: "0px",position: "absolute",left: 'calc(50% - ' + 28 + 'px)'}}/>
                {children}
              </Drawer.Content>
            </Drawer.Portal>
        </Drawer.Root>
  )
}

export default CustomDrawer
