import { ButtonBase } from "@mui/material";
import { Form } from "antd";

import {ReactComponent as StarIcon} from '../../assets/icons/star.svg';
import {ReactComponent as CheckedIcon} from '../../assets/icons/check-circle.svg';
import {ReactComponent as UncheckedIcon} from '../../assets/icons/uncheck-circle.svg';
import CustomInput from "../CommonComponents/CustomInput";
import CustomPhotoUpload from "../CommonComponents/CustomPhotoUpload";
import CustomRadio from "../CommonComponents/CustomRadio";
import CustomTextArea from "../CommonComponents/CustomTextArea";
import Loader from "../CommonComponents/Loader";
import { useStore } from "../../store";

type NewSongSingerFormProps =  {
    handleNewSingerFinish: (value: any) => void
    loading: boolean
}

export default function NewSingerInputForm ({handleNewSingerFinish, loading}: NewSongSingerFormProps) {
  const {store} = useStore();
  const text = store.text;

    const btnStyle = {
      color: 'white', 
      border: 'none', 
      borderRadius: '20px',
      padding: '10px 54px',
      margin: '10px 0',
      cursor: 'pointer',
      fontWeight: 'bold',
    };
  
    return (
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          fontFamily: "inherit"
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={handleNewSingerFinish}
        autoComplete="off"
      >
         <Form.Item
          name="singerPhoto"
          wrapperCol = {{span: 24}}
          style={{padding: "",color: "white",margin: "20px 0 35px 0"}}
        >
          <CustomPhotoUpload text={text["Add photo"]}/>
        </Form.Item>
        <Form.Item
          name="singerName"
          wrapperCol = {{span: 24}}
          rules={[
            {
              required: true,
              message: `! Singer Name is required`,
            },
            {
              validator: (_, value) =>
                value && value.length > 35
                  ? Promise.reject(new Error('Singer Name is too long'))
                  : Promise.resolve(),
            },
          ]}
          style={{padding: "",color: "white",margin: "20px 0 35px 0"}}
        >
          <CustomInput placeholder={text["Singer Name"]}  prefix={<StarIcon />}  />
        </Form.Item>
        <Form.Item
          name="singerNameMyanmar"
          wrapperCol = {{span: 24}}
          rules={[
            {
              validator: (_, value) =>
                value && value.length > 35
                  ? Promise.reject(new Error('Singer Name is too long'))
                  : Promise.resolve(),
            },
          ]}
          style={{padding: "",color: "white",margin: "20px 0 35px 0"}}
        >
          <CustomInput placeholder={text["Singer Name(Myanmar)"]}  prefix={<StarIcon />}  />
        </Form.Item>
        <Form.Item
          name="singerNameEnglish"
          wrapperCol = {{span: 24}}
          rules={[
            {
              validator: (_, value) =>
                value && value.length > 35
                  ? Promise.reject(new Error('Singer Name is too long'))
                  : Promise.resolve(),
            },
          ]}
          style={{padding: "",color: "white",margin: "20px 0 35px 0"}}
        >
          <CustomInput placeholder={text["Singer Name(English)"]}  prefix={<StarIcon />}  />
        </Form.Item>
        <Form.Item
          name="gender"
          wrapperCol = {{span: 24}}
          rules={[
            {
              required: true,
              message: `! Singer is required`,
            },
          ]}
          style={{color: "white",margin: "35px 0"}}
          initialValue={"male"} // value will be lower letter
        >
          <CustomRadio options={[
              { value: 'male', label: text.Male },
              { value: 'female', label: text.Female },
              { value: 'group', label: text.Group },
              { value: 'composer', label: text.Composer }
          ]} checkedIcon={<CheckedIcon width={'30px'}/>} uncheckedIcon={<UncheckedIcon width={'30px'}/>}/>
        </Form.Item>
        <Form.Item
          name="remark"
          wrapperCol = {{span: 24}}
          style={{color: "white",margin: "35px 0 18px 0"}}
          rules={[
            {
              validator: (_, value) =>
                value && value.length > 120
                  ? Promise.reject(new Error('Remark is too long'))
                  : Promise.resolve(),
            },
          ]}
        >
          <CustomTextArea upperText={text["Remark ( Optional )"]}  />
        </Form.Item>
        
        <div style={{display: "flex",justifyContent: "center"}}>
          <ButtonBase disabled={loading} style={{...btnStyle,backgroundColor: '#007bff'}} type="submit">
            {loading ? <Loader loaderColor="black" btn/> : text.Save}
          </ButtonBase>
        </div>
      </Form>
    )
  }