import React, { useEffect, useRef, useState } from 'react'
import BackHeader from './BackHeader'
import { useStore } from '../../store';
import { DataType, SongDataType, SongType } from '../../types';
import { endpoints } from '../../api/endpoints';
import api from '../../api/apiClient';
import CustomList from './CustomList';
import { useLocation } from 'react-router-dom';

export default function SongVersionsList() {
    const {store} = useStore();
    const text = store.text;
    const [data, setData] = useState<DataType>(null);
    const [loading,setLoading] = useState(false);
    const [hasMore,setHasMore] = useState(true);
    const [page,setPage] = useState(1);
    const firstMounted = useRef(true);

    const location = useLocation();
    const songId = location.state.songId;
  
  
    const fetchData = async () => {
      if(page === 1) setLoading(true);
  
      const result: any = await api.get(endpoints.song.getVersionsBy + `?songId=${songId}current=${page}&limit=10`);
      if(page === 1) setLoading(false);
      if(result.code === '200'){
        const d : SongDataType = result.data;
        setData((pre) => {
          let obj: any = {...pre};
          let arr : any;
          if(page > 1){
            if(pre && d) arr = [...pre?.by,...d?.by]
            obj.by = arr;
            return obj
          }else{
            return d
          }
        });
      }else{
        if(page > 1){
          setHasMore(false);
        }else{
          setHasMore(false);
          setData(null);
        }
      }
    }
    
    useEffect(() => {
      if(firstMounted.current && data) return;
      fetchData();
    },[page])
  
    useEffect(() => {
      if(data?.by && data?.pagination){
        if(data?.by?.length === data?.pagination?.total) setHasMore(false);
      }
    },[data])
  
    const handleFetchMore = () => {
      setPage(pre => pre + 1)
    }

    useEffect(()=> {
      if(firstMounted.current) firstMounted.current = false;
    }, [])
  return (
    <div>
      <div>
        <BackHeader/>
        <h1 style={{position: 'fixed', top: 0, left: "50%",transform: 'translate(-50%, 0)',color: "white", fontSize: "22px"}}>
          {text['Versions']}
        </h1>
      </div>
    <div style={{padding: "0 15px"}}>
        <CustomList
          data={data}
          type={'Song'}
          showFavorite
          loading={loading}
          handleFetchMore={handleFetchMore}
          hasMore={hasMore}
          scrollHeight={store.screenHeight - 15}
          showVersions={false}
        />
    </div>
    </div>
  )
}
