import config from "../config";
import MetaAudiencePlugin from '../plugins/metaAudiencePlugin';

export class MetaAudienceService {

    private interstitialPlacementId = config.facebook.interstitialPlacementId;
    private bannerPlacementId = config.facebook.bannerPlacementId;
    private currentNetwork = 'meta';

    constructor() {
    }

    async showInterstitial() {
        try{
            await MetaAudiencePlugin.showInterstitialAd({placementId: this.interstitialPlacementId});
            return this.currentNetwork;
        }catch(err){
            console.log('err from loadInterstitial: ', err);
            return null
        }
    }

    async showBanner(position: 'TOP' | 'CENTER' | 'BUTTON' = 'BUTTON') {
        try{
            await MetaAudiencePlugin.showBannerAd({placementId: this.bannerPlacementId, position: position});
            return this.currentNetwork;
        }catch(err){
            console.log("err from loadBanner: ",err);
            return null
        }
    }

    async removeBanner() {
        try{
            await MetaAudiencePlugin.removeBannerAd();
        }catch(err){
            console.log("err from removeBanner: ",err);
        }
    }
}