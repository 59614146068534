/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import {ReactComponent as ArrowBackIcon} from '../../assets/icons/arrow-back.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonBase } from '@mui/material';

type BackHeaderProps = {
  to?: string;
  state?: any;
  arrowColor?: string
}

export default function BackHeader({to, state, arrowColor = 'white'}: BackHeaderProps) {
  const navigate =  useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    if(to){
      if(state){
        navigate(to,{state: {...state, preRoute: location.pathname}});
      }else{
        navigate(to,{state: {...location.state, preRoute: location.pathname}});
      }
    }else{
      navigate(-1);
    }
  }
  return (
      <ButtonBase onClick={handleGoBack}  style={{width: "50px",height: "50px",marginLeft: "5px",marginTop: "5px",zIndex: 1000}}>
          <ArrowBackIcon css={css`path:first-of-type { fill: ${arrowColor}}`}  style={{cursor: "pointer"}}/>
      </ButtonBase>
  )
}
