import React, { UIEvent, useEffect, useRef, useState } from 'react'

import Footer from '../CommonComponents/Footer'
import { useStore } from '../../store'
import CustomList from '../CommonComponents/CustomList';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { SongDataType, SongType, storeType } from '../../types';
import { useLocation } from 'react-router-dom';
import { getDecoded } from '../../utils';

export default function Library() {
  const location  = useLocation();
  const navigatedFromSongDetail = location.state?.preRoute === '/songDetail';

  const {store, updateStoreFunc} = useStore();
  const text = store.text;
  const {searchQuery,scrollPos, selectedTag, page, hasMore, loading, data, refresh} = store.library;

  const firstMounted = useRef(true);

  const dynamicApi = useRef(endpoints.song.getFav);

  const tags = ['My Songs','Playlist'];

  const fetchData = async () => {
    if(page === 1) updateStoreFunc('library','loading',true);
    let p = 1;
    if(searchQuery){
      p = 1
    }else{
      p = page
    }

    if(selectedTag === "Playlist"){
      dynamicApi.current = endpoints.playlist.getPlaylist;
    }else{
      dynamicApi.current = endpoints.song.getChordsBy;
    }
    const decoded = getDecoded();

    const result: any = await api.get(dynamicApi.current + `?keyword=${searchQuery}&current=${p}&limit=10&userId=${decoded.userId}`);
    if(p === 1) updateStoreFunc('library','loading',false);
  
    if(result.code === '200'){
      updateStoreFunc(null,null,null,(pre: storeType['store']) => {
        const d : SongDataType = result.data;
        let arr: any;
        let o = {...pre};
        let a: any = {...o.library.data};
        if(p > 1){
          arr = [...a?.by,...d?.by]
          a.by = arr;
          o.library.data = a;
          return o;
        }else{
          a = d;
          o.library.data = a;
          return o;
        }
      })
  
    }else{
      if(p > 1){
        updateStoreFunc('library','hasMore',false);
      }else{
        updateStoreFunc('library','hasMore',false);
        updateStoreFunc('library','data', null);
      }
    }
  }
  
  useEffect(() => {
    if(firstMounted.current && data) return;
    fetchData();
  },[page, selectedTag, searchQuery, refresh])

  useEffect(() => {
    if(firstMounted.current) return;
    if(data?.by?.length === data?.pagination?.total) updateStoreFunc('library','hasMore',false);
  },[data])

  const handleFetchMore = () => {
    updateStoreFunc('library','page', page + 1)
  }

  const handleScroll = (event: any) => {
    const scrollTop = event.target.scrollTop;
    updateStoreFunc('library','scrollPos',scrollTop);
  }

  const handleTagChange = (v: string) => {
    updateStoreFunc('library','selectedTag',v)
    updateStoreFunc('library','page',1)
    updateStoreFunc('library','hasMore', true)
  }

  const handleSearchChange = (v: string) => {
    if(v === ''){
      updateStoreFunc('library','hasMore',true);
    }
    updateStoreFunc('library','page', 1);
    updateStoreFunc('library','searchQuery',v);
  }

  useEffect(()=>{
    if(firstMounted.current) firstMounted.current = false;
    if(navigatedFromSongDetail){
      updateStoreFunc('library','selectedTag','My Songs')
    }
  },[])

  let type: any;
  if(selectedTag === "My Songs"){
    type = "Song"
  }else if(selectedTag === "Playlist"){
    type = "Playlist"
  }

  return ( 
    <div>
      <div style={{padding: "0 15px"}}>
        <h1 className='custom-font' style={{color: "white",fontSize: "30px"}}>{text.Library}</h1>
        <CustomList
            data={data}
            searchQuery={searchQuery}
            tags={tags}
            type={type}
            selectedTag={selectedTag}
            onSearchChange={handleSearchChange}
            onTagSelect={handleTagChange}
            showSearchBar
            showTagBar
            loading={loading}
            handleFetchMore={handleFetchMore}
            hasMore={hasMore}
            scrollHeight={store.screenHeight - 190}
            handleScroll={handleScroll}
            scrollPos={scrollPos}
        />
      </div>
      <Footer/>
    </div>
  )
}
