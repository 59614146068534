/** @jsxImportSource @emotion/react */
import { css} from '@emotion/react';
import { Box, CircularProgress } from '@mui/material'
import { Color } from 'antd/es/color-picker';
import React from 'react';


type LoaderType = {
    btn?: boolean;
    style?: React.CSSProperties;
    loaderColor?: React.CSSProperties['color']
}
export default function Loader({btn = false, style, loaderColor = '#348DC7'} : LoaderType) {
  return (
    <Box sx={{ display: 'flex',justifyContent: "center",marginTop: `${btn ? "0rem": "8rem"}`,...style}} css={css`.MuiCircularProgress-root{color: ${loaderColor};}`}><CircularProgress size={btn ? "1.2rem" : "3rem"} /></Box>
  )
}