import React, { useState, KeyboardEvent, ChangeEvent } from 'react';

import {ReactComponent as TagIcon} from '../../assets/icons/tag.svg';
import CustomInput from './CustomInput';

interface TagsInputProps {
  value?: string[];
  onChange?: (tags: string[]) => void;
}

const TagsInput: React.FC<TagsInputProps> = ({ value = [], onChange }) => {

  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState<string[]>(value);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      e.preventDefault();
      const newTags = [...tags, inputValue.trim()];
      setTags(newTags);
      setInputValue('');
      onChange?.(newTags);
    }
  };

  const handleRemoveTag = (index: number) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
    onChange?.(newTags);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if(e.target.value.length > 30 ) return e.preventDefault();
    setInputValue(e.target.value);
  };

  return (
    <div>
      <CustomInput 
       placeholder='Tags'
       prefix={<TagIcon />}
       value={inputValue}
       onChange={handleChange}
       onKeyDown={handleKeyDown}
       spellCheck={false}
      />
      <div style={styles.tagContainer} className='remove-scrollbar'>
        {tags.map((tag, index) => (
          <div key={index} style={styles.tag}>
            {tag}
            <span style={styles.removeTag} onClick={() => handleRemoveTag(index)}> × </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  tagContainer: {
      flexWrap: 'wrap',
      padding: '5px',
      border: '1px solid #333333',
      borderRadius: '4px',
      backgroundColor: '#1A1A1A',
      margin: "35px 0",
      height: "150px",
      overflowX: "scroll"
  } as React.CSSProperties,
  tag: {
      display: 'inline-block',
      backgroundColor: '#333',
      color: 'white',
      borderRadius: '20px',
      padding: '5px 10px',
      margin: '5px',
      fontSize: '14px',
      height: "20px"
  } as React.CSSProperties,
  removeTag: {
      marginLeft: '8px',
      cursor: 'pointer',
  } as React.CSSProperties,
 };



export default TagsInput;
