import React, { useEffect, useState } from 'react';
import { Drawer } from 'vaul';

import config from '../../config';
import CustomWheelPicker from '../CommonComponents/CustomWheelPicker';
import CustomButton from "../CommonComponents/CustomButton";
import CustomDrawer from '../CommonComponents/CustomDrawer';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { ChordsType } from '../../types';
import { useStore } from '../../store';

interface ChordAddDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddChord: (chord: string) => void;
}
const ChordAddDrawer: React.FC<ChordAddDrawerProps> = ({ isOpen, onClose, handleAddChord }) => {
  const {store} = useStore();
  const text = store.text;

  const [main, setMain] = useState( [
    { value: "A", id: "0" },
    { value: "Ab", id: "1" },
    { value: "B", id: "2" },
    { value: "Bb", id: "3" },
    { value: "C", id: "4" },
    { value: "Cb", id: "5" },
    { value: "D", id: "6" },
    { value: "Db", id: "7" },
    { value: "E", id: "8" },
    { value: "Eb", id: "9" },
    { value: "G", id: "10" },
    { value: "Gb", id: "11" },
    { value: "F", id: "12" },
    { value: "Fb", id: "13" }
  ]);
  
  const [type, setType] = useState([
    { id: "0", value: "-" },
    { id: "1", value: "maj" },
    { id: "2", value: "m" },
    { id: "3", value: "7" },
    { id: "4", value: "maj7" },
    { id: "5", value: "m7" },
    { id: "6", value: "#"}
  ]);

  const [extension, setExtension] = useState([
    { value: "-", id: "0" },
    { value: "add9", id: "1" },
    { value: "add11", id: "2" },
    { value: "add13", id: "3" },
    { value: "sus", id: "4" },
    { value: "sus4", id: "5" },
    { value: "m7", id: "6" },
    { value: "6", id: "7" }
  ]);

  const c = localStorage.getItem('selectedChord');
  type d = {id: string, value: string};
  let selectedChord : {m: d, t: d, e: d} | null = null;
  if(c) {
    selectedChord = JSON.parse(c);
  }
  
  const [selectedMain, setSelectedMain] = useState<{id: string,value: string}>(selectedChord?.m || main[0]);
  const [selectedType, setSelectedType] = useState<{id: string,value: string}>(selectedChord?.t || type[0]);
  const [selectedExtension, setSelectedExtension] = useState<{id: string,value: string}>(selectedChord?.e || extension[0]);

  const handleMainChange = (value: {id: string,value: string}) => {
    setSelectedMain(value);
  }
  
  const handleTypeChange = (value: {id: string,value: string}) => {
    setSelectedType(value);
  }

  const handleExtensionChange = (value: {id: string,value: string}) => {
    setSelectedExtension(value);
  }

  useEffect(()=> {
    localStorage.setItem('selectedChord',JSON.stringify({m: selectedMain,t: selectedType,e: selectedExtension}));
  },[selectedType, selectedExtension, selectedMain])

  const getChords = async () => {
    const result : any = await api.get(endpoints.chord.getChords);
    if(result.code !== '200') return;
    const chords : ChordsType = result.data;
    setMain(chords.main);
    setType(chords.type);
    setExtension(chords.extension);
  }

  useEffect(()=> {
    getChords();
  },[])

  const formatChord  = () => {
    const m = selectedMain.value;
    const t = selectedType.value === '-' ? "" : selectedType.value;
    const e = selectedExtension.value === '-' ? "" : selectedExtension.value;
    const chord = m+t+e
    handleAddChord(chord)
  }

  return (
    <div>
        <CustomDrawer isOpen={isOpen} onClose={onClose}>
            <div style={{display: 'flex'}} >
              <div data-vaul-no-drag>
                <CustomWheelPicker uniqueId='wheel-main' data={main} handleDataChange={handleMainChange} selectedId={selectedMain.id}/>
              </div>
              <div data-vaul-no-drag>
                <CustomWheelPicker uniqueId='wheel-type' data={type} handleDataChange={handleTypeChange} selectedId={selectedType.id}/>
              </div>
              <div data-vaul-no-drag>
                <CustomWheelPicker uniqueId='wheel-extension' data={extension} handleDataChange={handleExtensionChange} selectedId={selectedExtension.id}/>
              </div>
            </div>
            <div style={{display: "flex",justifyContent: 'center'}}>
              <CustomButton onClick={() => formatChord()} active style={{textAlign: 'center',paddingTop: "15px",paddingBottom: "15px",zIndex: 4000,marginBottom: "50px"}}>
                {text['Add Chord']}
              </CustomButton>
            </div>
        </CustomDrawer>
    </div>
  );
};

export default ChordAddDrawer;
