// Import Swiper React components
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode} from 'swiper/modules';
import { ButtonBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { endpoints } from '../../api/endpoints';
import api from '../../api/apiClient';
import { ArtistDataType } from '../../types';
import CustomImage from '../CommonComponents/CustomImage';
import {ReactComponent as ViewIcon} from '../../assets/icons/view-arrow.svg'
import { useStore } from '../../store';


type ArtistsSliderProps = {
    loading: boolean,
    artists: ArtistDataType | undefined;
}

const ArtistsSlider : React.FC<ArtistsSliderProps> = ({loading, artists}) => {
  const {store} = useStore();
  const text = store.text;
  const navigate = useNavigate();

  const handleViewClick = () => {
    navigate('/home/artists')
  }

  return (
    <div style={{ padding: '10px 0 0 0',marginBottom: "50px"}}>
          <div style={{padding: "14px 14px 16px 14px", display: 'flex',flexDirection: "row",justifyContent:"space-between", alignItems: 'center'}}>
            <h2 className="" style={{fontSize: "20px",color: "white",margin: 0}}>{text.Artists}</h2>
            {/* <ButtonBase style={{
              padding: '12px 30px',
              borderRadius: '20px',
              fontSize: "10px",
              backgroundColor: '#ffffff',
              color: '#3D3D3D',
              border: 'none',
              cursor: 'pointer',
              boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
              marginBottom: '20px',
            }} onClick={handleViewClick}>
              View
            </ButtonBase> */}
            <ViewIcon onClick={handleViewClick}/>
          </div>
          <div style={{padding: "0 14px"}}>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={10}
                freeMode={true}
                modules={[FreeMode]}
                className="mySwiper"
              >
              {artists?.by?.map(singer => (
                <SwiperSlide style={{display: 'flex',flexDirection: "column",alignItems: "center", width: "82px"}} key={singer.artist_id}>
                    <CustomImage
                      onClick={(e)=>{
                        const obj = {selectedTag: 'Artist', headerData: singer};
                        navigate('/home/songListsBySpecificId',{state: obj});
                      }}
                      src={singer.image}
                      alt={`Song ${singer.name}`}
                      style={{border: "1.5px solid rgba(255, 255, 255, .3)"}}
                      width='80px' height="80px" borderRadius='40px'
                    />
                    <img
                    />
                    <h2 className="custom-font" style={{color: "white",textAlign: "center",fontSize: "15px"}}>{singer.name}</h2>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
  )
}

export default ArtistsSlider;