const config = {
    // backendApi: "http://localhost:8000/api",
    backendApi: "https://dev.ywar.com/guitar",
    socketApi: "",
    screenWidth: "414px",
    termsAndConditionsLink: "https://mmguitar.ywar.com/terms.html",
    videoHeight: Math.floor(window.innerHeight * ( 60 / 100 )),
    tiktok: {
        clientKey: "sbawp2gihxd6l6y9tc",
        redirectUri: "guitar://auth"
    },
    google: {
        clientKey: "971825694230-cphs2re1de3jubom7as5m4b8ii9nmq72.apps.googleusercontent.com",
        adMobAppId: "ca-app-pub-6182318181391980~4341796268",
        adMobInterstitialAdId: "ca-app-pub-3940256099942544/1033173712",
        adMobBannerAdId: "ca-app-pub-3940256099942544/6300978111"
    },
    facebook: {
        appId: "960012162480817",
        interstitialPlacementId: "497375973266663_501190509551876",
        bannerPlacementId: "497375973266663_501784346159159",
    },
    color: {
        primaryColor: "#2D333B",
        secondaryColor: "#8054DB",
        sideBarColor: "#22272E",
        mainColor: "#1C2128",
        footerBgColor: "#1F1F1F",
        pageSelectedColor: "grey",
        pageNameColor: "#474747",
        htmlColor: '#1A1A1A'
    }
}
export default config ;