/** @jsxImportSource @emotion/react */
import React, { ReactNode } from "react";
import { Select, SelectProps } from "antd";
import { css, Global } from "@emotion/react";

type CustomSelectProps = SelectProps & {
  prefixIcon?: ReactNode;
};

const CustomSelect = ({ prefixIcon, children, ...rest }: CustomSelectProps) => {
  const selectHeight = "52px";
  return (
    <div id="hello" className="custom-font" style={{position: "relative",height: selectHeight}} css={css`
      .prefix-icon-wrapper {
        position: absolute;
        z-index: 1;
        width: 3rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-select .ant-select-selector {
        background-color: #1A1A1A !important;
        border-color: #333333 !important;
        color: white !important;
        height: ${selectHeight} !important;
      }

      .ant-select-selection-search-input{
        font-size: 15px !important;
        font-weight: 500 !important;
        padding-left: calc(${prefixIcon ? '3rem - 10px' : '0px'}) !important;
        padding-top: 1rem;
        height: ${selectHeight} !important;
      }

      .ant-select-selection-placeholder{
          color: gray !important;
          padding-left: calc(${prefixIcon ? '3rem - 10px' : '0px'}) !important;
      } 

      .ant-select-arrow{
        color: white;
      }

      .ant-select-selection-item {
        padding-left: calc(${prefixIcon ? '3rem - 10px' : '0px'}) !important;
      }
    `}>
      <Global
        styles={css`
          .ant-select-item-option-content {
            color: white !important;
          }
          .ant-select-item-option-selected .ant-select-item-option-content{
            color: black !important;
          }
          .ant-select-selection-item {
            color: white !important;
          }

          .ant-empty-description{
            color: white !important;
          }
        `}
      />
      {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
      <Select 
        style={{fontFamily: "inherit"}}
        dropdownStyle={{backgroundColor: "#1A1A1A",borderColor: "#333333"}} 
        {...rest}
      >
        {children}
      </Select>
    </div>
  );
};

export default CustomSelect;