import React, { useEffect, useRef, useState } from 'react';

import CustomDrawer from '../CommonComponents/CustomDrawer';
import { useStore } from '../../store';
import CustomButton from '../CommonComponents/CustomButton';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { PlaylistDataType, PlaylistType } from '../../types';
import { ButtonBase } from '@mui/material';
import {ReactComponent as PublicIcon} from '../../assets/icons/public.svg'
import {ReactComponent as PublicSelectedIcon} from '../../assets/icons/public-selected.svg'
import {ReactComponent as PrivateSelectedIcon} from '../../assets/icons/private-selected.svg'
import {ReactComponent as ArrowBackIcon} from '../../assets/icons/arrow-back.svg';
import {ReactComponent as PrivateIcon} from '../../assets/icons/private.svg'
import {ReactComponent as CheckIcon} from '../../assets/icons/check-circle2.svg'
import {ReactComponent as UncheckIcon} from '../../assets/icons/uncheck-circle2.svg'
import { getDecoded } from '../../utils';
import CustomInput from '../CommonComponents/CustomInput';
import { InputRef, message, Modal } from 'antd';

interface ChordAddDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  songId: number | null;
  chordId: number | null;
  editMode: boolean;
  playlistId?: number; //for edit
}
const PlaylistDrawer: React.FC<ChordAddDrawerProps> = ({ isOpen, onClose, editMode = false, songId, chordId, playlistId }) => {
  const {store, updateStoreFunc} = useStore();
  const text = store.text;

  const [playlist,setPlaylist] = useState<PlaylistDataType>();
  const [addModeOn, setAddModeOn] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [newPlaylistStatus, setNewPlaylistStatus ] = useState('public');
  const [newPlaylistName, setNewPlaylistName] = useState("");
  const newPlaylistNameInputRef = useRef<InputRef | null>(null);

  const [editPlaylistStatus, setEditPlaylistStatus ] = useState('');
  const [editPlaylistName, setEditPlaylistName] = useState('');
  const [editPlaylistImg, setEditPlaylistImg] = useState('');

  const getPlaylist = async () => {
    const decoded = getDecoded();
    const result : any = await api.get(endpoints.playlist.getPlaylist + `?userId=${decoded.userId}&songId=${songId}&chordId=${chordId}&current=1&limit=20`);
    if(result.code !== '200') return;
    const data : PlaylistDataType  = result.data;
    setPlaylist(data);
    if(editMode){
        const p = data.by.filter(d => d.id === playlistId)[0];
        if(!p) return;
        setEditPlaylistStatus(p.status);
        setEditPlaylistName(p.name);
        setEditPlaylistImg(p.image);
    }
  };

  useEffect(()=> {
    if(!addModeOn) return;
    if(newPlaylistNameInputRef.current){
        newPlaylistNameInputRef.current.focus();
    }
  },[addModeOn, newPlaylistStatus])

  useEffect(()=> {
    if(isOpen) getPlaylist();
  },[isOpen])

  const playlistCard = (playListId: number, name: string, status: string,selected: string, index: number) => {

    const cardClick = async () => {
        let result: any ;
        const params = {
            id: playListId,
            songId: songId,
            chordId: chordId
        }
        if(selected === 'false') {
            result = await api.post(endpoints.playlist.addSongPlayList, params)
        }else{
            result = await api.delete(endpoints.playlist.deletePlayList, {params});
        }
        if(result.code !== '200') {
            message.error(result.message);
            return;
        }
        message.success(result.message);
        onClose();
    }
    
    return (
       <ButtonBase onClick={cardClick} key={index} style={{marginTop: "12px",display: "flex",justifyContent: "space-between",alignItems: "center",padding: "5px 18px",border: "1px solid #333333", width: "100%"}}>
            <div style={{display: "flex",alignItems: "center"}}>
            {
                selected === 'true' ?
                <CheckIcon/>
                :
                <UncheckIcon/>
            }
            <h2 style={{marginLeft: "24px",color: '#EEEEEE',fontSize: "16px"}}>{name}</h2>
            </div>
            {
                status === 'public' ?
                <PublicIcon/>
                :
                <PrivateIcon/>
            }
        </ButtonBase>
    )
  }

  const playlistDisplay = ()  => {
     return (
        <div style={{height: "500px", padding: "50px 20px 0 20px",}}>
            <div style={{display: 'flex',justifyContent: 'space-between'}}>
                <h1 style={{fontSize: "20px", color: '#909090'}}>{text['Playlist']}</h1>
                <CustomButton onClick={()=> setAddModeOn(true)} active={true} style={{paddingLeft: "30px",paddingRight: "30px"}}>{text['New Playlist']}</CustomButton>
            </div>
            <div data-vaul-no-drag className="scrollable-y remove-scrollbar" style={{height: "420px"}}>
                {
                    playlist?.by?.map((p, index) => {
                        return playlistCard(p.id, p.name, p.status, p.choose, index)
                    })
                }
            </div>
        </div>
     )
  }

  const addPlaylistDisplay = () => {

    const addPlayList = async () => {
        setAddLoading(true);
        const params = {
            name : newPlaylistName,
            status: newPlaylistStatus
        }
        const result : any = await api.post(endpoints.playlist.addPlayList, params);
        setAddLoading(false);
        if(result.code !== '200') {
            message.error(result.message);
            return;
        }
        message.success("Successfully Created New Playlist!");
        getPlaylist();
        setAddModeOn(false);
        setNewPlaylistName('');
        setNewPlaylistStatus('public');
    }

    return (
        <div style={{height: "500px", padding: "20px 40px 0 40px",}} >
            <ButtonBase onClick={() => setAddModeOn(false)}  style={{width: "20px",height: "50px",margin: 0}}>
                <ArrowBackIcon  style={{cursor: "pointer"}}/>
            </ButtonBase>
            <CustomInput value={newPlaylistName} onChange={(e) => setNewPlaylistName(e.target.value)} ref={newPlaylistNameInputRef} style={{backgroundColor: "#272727", borderColor: 'gray', color: "white"}} placeholder={text['Name'] + "..."}/>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: "20px"}}>
                <div style={{display: 'flex', gap: '30px'}}>
                    <div style={{display: 'flex', alignItems: "center", gap: "10px"}}  onClick={() => setNewPlaylistStatus('public')}>
                        {newPlaylistStatus === 'public' ? <PublicSelectedIcon/> : <PublicIcon />}
                        <h1 style={{fontSize: "15px", color: '#EEEEEE'}}>Public</h1>
                    </div>
                    <div style={{display: 'flex', alignItems: "center", gap: "10px"}} onClick={() => setNewPlaylistStatus('private')}>
                        {newPlaylistStatus === 'private' ? <PrivateSelectedIcon/> : <PrivateIcon />}
                        <h1 style={{fontSize: "15px", color: '#EEEEEE'}}>Private</h1>
                    </div>
                </div>
                <CustomButton loading={addLoading} active={true} onClick={addPlayList} style={{width: "100px", whiteSpace: 'nowrap'}}>
                    {text['Save']}
                </CustomButton>
            </div>
        </div>
    )
  }

  const editPlaylistDisplay = () => {
    const handleEdit = async () => {
        setEditLoading(true);
        const params = {
            id: playlistId,
            name : editPlaylistName,
            status: editPlaylistStatus,
        }
        const result : any = await api.put(endpoints.playlist.updatePlayList, params);
        setEditLoading(false);
        if(result.code !== '200') {
            message.error(result.message);
            return;
        }
        message.success("Successfully Edited Playlist!");
        updateStoreFunc('library','refresh', !store.library.refresh);
        onClose();
    }

    const handleDelete = () => {
        onClose();
        Modal.confirm({
            title: text['Are you sure?'],
            content: text['Do you want to delete'],
            okText: text['Yes'],
            cancelText: text['No'],
            centered: true,
            mask: true,
            maskClosable: true,
            async onOk() {
                setDeleteLoading(true);
                const params = {
                    id: playlistId,
                }
                const result : any = await api.delete(endpoints.playlist.deletePlayList, {params});
                setDeleteLoading(false);
                if(result.code !== '200') {
                    message.error(result.message);
                    return;
                }
                message.success("Successfully Deleted Playlist!");
                updateStoreFunc('library','refresh', !store.library.refresh);
            },
          });
    }

    return (
        <div style={{height: "500px", padding: "20px 40px 0 40px",}} >
            <img style={{width: "100px", height: "100px", borderRadius: "7px"}} src={editPlaylistImg} alt='playlist name'/>
            <CustomInput value={editPlaylistName} onChange={(e) => setEditPlaylistName(e.target.value)} ref={newPlaylistNameInputRef} style={{backgroundColor: "#272727", borderColor: 'gray', color: "white", marginTop: "20px"}} placeholder={text['Name'] + "..."}/>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: "20px"}}>
                <div style={{display: 'flex', gap: '30px'}}>
                    <div style={{display: 'flex', alignItems: "center", gap: "10px"}}  onClick={() => setEditPlaylistStatus('public')}>
                        {editPlaylistStatus === 'public' ? <PublicSelectedIcon/> : <PublicIcon />}
                        <h1 style={{fontSize: "15px", color: '#EEEEEE'}}>Public</h1>
                    </div>
                    <div style={{display: 'flex', alignItems: "center", gap: "10px"}} onClick={() => setEditPlaylistStatus('private')}>
                        {editPlaylistStatus === 'private' ? <PrivateSelectedIcon/> : <PrivateIcon />}
                        <h1 style={{fontSize: "15px", color: '#EEEEEE'}}>Private</h1>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: "20px"}}>
                <CustomButton loading={deleteLoading} active={true} onClick={handleDelete} style={{width: "150px", whiteSpace: 'nowrap', backgroundColor: '#EF3333'}}>
                    {text['Delete']}
                </CustomButton>
                <CustomButton loading={editLoading} active={true} onClick={handleEdit} style={{width: "150px", whiteSpace: 'nowrap'}}>
                    {text['Edit']}
                </CustomButton>
            </div>
        </div>
    )
  }

  return (
    <div>
        <CustomDrawer isOpen={isOpen} onClose={onClose} outsideInteraction={false}>
            {
                editMode ?
                editPlaylistDisplay() :
                addModeOn ? addPlaylistDisplay() : playlistDisplay()
            }
            
        </CustomDrawer>
    </div>
  );
};

export default PlaylistDrawer;
