import React, { useEffect, useRef, useState } from 'react';
import { App, App as CapacitorApp } from '@capacitor/app';
import { Modal } from 'antd';
import { useStore } from '../../store';

const useBackButtonListener = () => {
  const isListenerAttached = useRef(false);
  const isModalOpen = useRef(false);

  const {store} = useStore();
  const text = store.text;

  useEffect(() => {
    if (!isListenerAttached.current) {
      isListenerAttached.current = true;

      CapacitorApp.addListener('backButton', ({ canGoBack }) => {
        const notHomePage = window.location.pathname !== '/home';
        const notLoginPage = window.location.pathname !== '/login';
        if (canGoBack && notHomePage && notLoginPage) {
          window.history.back();
        } else {
          if(isModalOpen.current) return;
          isModalOpen.current = true;
          Modal.confirm({
            title: text['Exit App'],
            content: text['Are you sure you want to exit?'],
            okText: text['Yes'],
            cancelText: text['No'],
            centered: true,
            onOk() {
              App.exitApp()
            },
            onCancel() {
              isModalOpen.current = false;
            },
            afterClose() {
              isModalOpen.current = false;
            }
          });
        }
      });
    }
  }, []);
};

export default useBackButtonListener;

