/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { ButtonBase } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode} from 'swiper/modules';
import { Transposer } from 'chord-transposer';
import Skeleton from 'react-loading-skeleton';
import { message, Modal } from 'antd';
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { KeepAwake } from "@capacitor-community/keep-awake";

import {ReactComponent as Tag} from '../../assets/icons/tag2.svg';
import {ReactComponent as SettingOn} from '../../assets/icons/setting-on.svg';
import {ReactComponent as SettingOff} from '../../assets/icons/setting-off.svg';
import {ReactComponent as Copy} from '../../assets/icons/copy.svg';
import {ReactComponent as Edit} from '../../assets/icons/edit.svg';
import {ReactComponent as LightMode} from '../../assets/icons/light-mode.svg';
import {ReactComponent as DarkMode} from '../../assets/icons/dark-mode.svg';
import {ReactComponent as CameraOn} from '../../assets/icons/camera-on.svg';
import {ReactComponent as CameraOff} from '../../assets/icons/camera-off.svg';
import {ReactComponent as ViewIcon} from '../../assets/icons/view-arrow.svg'
import {ReactComponent as PlaylistOnIcon} from '../../assets/icons/plus-circle-on.svg'
import {ReactComponent as PlaylistOffIcon} from '../../assets/icons/plus-circle-off.svg'
import {ReactComponent as ReportOnIcon} from '../../assets/icons/report-on.svg';
import {ReactComponent as ReportOffIcon} from '../../assets/icons/report-off.svg';
import {ReactComponent as BackArrowIcon} from '../../assets/icons/arrow-back.svg';
import {ReactComponent as HalfStar} from '../../assets/icons/half-star.svg';
import {ReactComponent as FullStar} from '../../assets/icons/fill-star.svg';
import {ReactComponent as EmptyStar} from '../../assets/icons/empty-star.svg';
import {ReactComponent as LyricsImageOnIcon} from '../../assets/icons/lyrics-image-on.svg';
import {ReactComponent as LyricsImageOffIcon} from '../../assets/icons/lyrics-image-off.svg';
import { customSplit, formatNumber, getDecoded, removeChordFromLyrics } from '../../utils';

import BackHeader from './BackHeader';
import { useStore } from '../../store';
import CustomButton from './CustomButton';
import CustomDrawer from './CustomDrawer';
import CustomWheelPicker from './CustomWheelPicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { SongDetailsType, SongType } from '../../types';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { useRouteTracker } from "./RouteTracker";
import VideoCapture from "./VideoCapture";
import config from "../../config";
import { AdService } from "../../ads/ad";
import CustomPhotoUpload from "./CustomPhotoUpload";
import CustomImage from "./CustomImage";

const ads = new AdService();

const SongDetail: React.FC = () => {
   let [lyrics,setLyrics] = useState<string[]>([]);
   const [loading,setLoading] = useState(true);
   let [chordPositions, setChordPositions]  = useState<Record<string, string>>({});
   const [songDetailData,setSongDetailData] = useState<SongDetailsType>();
   const [scroll,setScroll] = useState<string>('0x');
   const [isSettingDrawerOpen,setIsSettingDrawerOpen] = useState(false);
   const [lyricsFontsize,setLyricsFontsize] = useState('16px');
   const [chordFontsize,setChordFontsize] = useState('16px');
   const [orgChordPositions,setOrgChordPositions] = useState<Record<string, string>>({});
   const [lightThemeOn, setLightThemeOn] = useState(false);
   const [lyricsImageOn, setLyricsImageOn] = useState(false);
   const [userRating, setUserRating] = useState<number | null>(null);
   const [lyricsImgFullScreen, setLyricsImgFullScreen] = useState(false);

   const location = useLocation();
   const navigate = useNavigate();
   const song : SongType = location.state;

   const formattedLyrics = useRef('');

   const lyricsImgRef = useRef<any>();
   const onZoomUpdate = useCallback(({ x, y, scale }: any) => {
    const { current: img}: any = lyricsImgRef;
      if (img) {
        const value = make3dTransformValue({ x, y, scale });
        img.style.setProperty("transform", value);
      }
    }, []);

  const getDetail = async (chordId = song.chord_id) => {
    const result: any = await api.get(endpoints.song.getDetail+ `?songId=${song.song_id}&chordId=${chordId}`);
    setLoading(false);
    if(result.code !== "200") return message.error(result.message);
    const songDetail: SongDetailsType = result.data;
    const onlyLyricsImgAvailable = songDetail.details.chord_img && !songDetail.details.chord_pro;
    if(onlyLyricsImgAvailable) setLyricsImageOn(true);
    setSongDetailData(songDetail);
    const l = songDetail?.details?.chord_pro;
    formattedLyrics.current = l;
    const {positions,removedChordLyrics} = removeChordFromLyrics(l);
    setLyrics(removedChordLyrics);
    setUserRating(songDetail.rating.currentUserRating)
    setChordPositions(positions);
    setOrgChordPositions(positions);
  }

  const isUserScrolling = useRef(false);

  const handleTouchStart = () => isUserScrolling.current = true;

  const handleTouchEnd = () => isUserScrolling.current = false;
  
  

  const {store, updateStoreFunc} = useStore();
  const text = store.text;

  const headerHeight = store.cameraPreviewOn ? 0 : 110;

  const videoHeight = store.cameraPreviewOn ?  config.videoHeight : 0;

  const handleClickEdit = () => {
    localStorage.setItem("chordPro",formattedLyrics.current);
    // localStorage.setItem("lyrics",lyrics.join('\n'));
    const songInfo = {
      singerName: songDetailData?.details.artist.name,
      songName: songDetailData?.details.name,
      singerId: songDetailData?.details.artist.artist_id,
      chordId: songDetailData?.details.chord_id,
      songId: songDetailData?.details.id,
      songUid: songDetailData?.details.user_id,
      chordUid: songDetailData?.details.chord_uid
    }
    navigate('/dataEntry/addLyrics',{state: {songInfo: songInfo}});
  }

  const handleClickSetting = () => {
    setIsSettingDrawerOpen(true);
  }
  
  const handleClickScroll = () => {
    setScroll((prevScroll) => {
      const currentScrollValue = parseInt(prevScroll.replace('x', ''));
      const nextScrollValue = currentScrollValue < 5 ? currentScrollValue + 1 : 0;
      return `${nextScrollValue}x`;
    });
  }

  const handleTransposerChange = (level: string) => {
    let l = Number(level);
    // Gather all chords into a single string
    const allChords = Object.values(orgChordPositions).join(' ');

    // Transpose the gathered chords
    const transposedChordsString = Number(l) > 0
      ? Transposer.transpose(allChords).up(Math.abs(l)).toString()
      : Transposer.transpose(allChords).down(Math.abs(l)).toString();

    if(!transposedChordsString) return;

    // Split the transposed string back into individual chords
    const transposedChordsArray = transposedChordsString.split(' ');

    // Create a new object with the transposed chords
    const transposedChords: Record<string,string> = {};
    Object.keys(chordPositions).forEach((position, index) => {
      transposedChords[position] = transposedChordsArray[index];
    });

    setChordPositions(transposedChords);
  }

  const handleChordLevelChange = (t: {id: string,value: string}) => {
    if(t.value === '0'){
      setChordPositions(orgChordPositions);
    }else{
      handleTransposerChange(t.value);
    }
  }

  const handleFontSizeChange = (value: {id: string,value: string}) => {
    switch(value.value) {
      case '0':
        setLyricsFontsize("16px");
        setChordFontsize('16px')
        break;
      case '1':
        setLyricsFontsize("16.5px");
        setChordFontsize('16.5px')
        break;
      case '2':
        setLyricsFontsize("17px");
        setChordFontsize('17px')
        break;
      case '3':
        setLyricsFontsize("17.5px");
        setChordFontsize('17.5px')
        break;
      case '4':
        setLyricsFontsize("18px");
        setChordFontsize('18px')
        break;
    }
  }

  const handleThemeChange = () => {
    setLightThemeOn(pre => !pre)
  }

  const handleLyricsImageChange = () => {
    setLyricsImageOn(pre => !pre)
  }
  
  const decoded = getDecoded();
  const uid = decoded?.userId;
  const isUserOwnChord = (uid === songDetailData?.details.chord_uid) ? true : false;

  const backgroundColor = lightThemeOn? '#E9E9E9' : config.color.htmlColor;
  const headerColor = lightThemeOn ? 'black' : 'white';
  const lyricsColor = lightThemeOn ?'#111111' : '#FFFFFF';
  const chordColor = lightThemeOn ? '#2E5CFF' : '#FFB800';

  const bodyHeaderStyle = () => {

    let style = {
      

    } as React.CSSProperties

    if(store.cameraPreviewOn){
      style =  {
        ...style,
        paddingTop: 0,
        backgroundColor: 'transparent',
        position: "fixed",
        top: videoHeight - 55,
        left: '50%',
        transform: 'translate(-50%)',
        textAlign: "center"
      } as React.CSSProperties
    }else{
      style =  {
        ...style,
        paddingTop: "60px",
        backgroundColor: backgroundColor,
      } as React.CSSProperties
    }
    return style;
  }

  const handleRating = async (rating: number) => {
    setUserRating(rating);
    Modal.confirm({
      title: "Rate",
      content: <div style={{display: 'flex',justifyContent: 'center',margin: "30px 0"}}>
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
        >
          {rating as number >= star ? getStar('full','2rem') : getStar('empty','2rem')}
        </span>
      ))}
    </div>,
      okText: text['Yes'],
      cancelText: text['No'],
      centered: true,
      async onOk() {
        const result: any = await api.post(endpoints.song.rate, {star: rating, songId: songDetailData?.details.id,chordId: songDetailData?.details.chord_id});
        if(result.code === '200'){
          message.success(result.message);
          getDetail();
        }else{
          message.error(result.message);
        }
      },
      onCancel () {
        setUserRating(songDetailData?.rating.currentUserRating || null);
      }
    });
  };

  const getAvgStars = (averageRating : number) => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      if (i <= Math.floor(averageRating)) {
        stars.push(getStar('full'));
      } else if (i === Math.ceil(averageRating) && averageRating % 1 >= 0.5) {
        stars.push(getStar('half'));
      } else {
        stars.push(getStar('empty'));
      }
    }

    return stars;
  }

  const getStar = (type: string, size: string = '1rem') => {
    const starStyle = {
      width: size,
      height: size,
      marginRight: '5px',
    } as React.CSSProperties;

    if(type === 'full'){
      return <FullStar style={starStyle} />;
    }else if (type === 'half'){
      return <HalfStar style={starStyle}/>; 
    }else{
      return <EmptyStar style={starStyle} />
    }
  }  

  const renderRating = () => {
    return (
      <>
        {
          !store.cameraPreviewOn &&
          <div style={{display: 'flex', justifyContent: 'space-between',padding: '10px 0',margin: "50px 10px 30px 10px",borderWidth: "1px 0 1px 0",borderStyle: "solid",borderColor: '#242424'}}>
            <div style={{display: 'flex', flexDirection: "column", alignItems: "center"}}>
              <div style={{fontSize: '13px',fontWeight: 'normal',color: lightThemeOn ? 'black' : 'white'}}>{songDetailData?.rating.totalUsers} ratings</div>
              <div style={{fontSize: '60px',fontWeight: 'bold',color: lightThemeOn ? 'black' : 'white'}}>{formatNumber(songDetailData?.rating.avg || 0)}</div>
              <div style={{}}>{getAvgStars(songDetailData?.rating.avg || 0)}</div>
            </div>
            <div style={{borderStyle: 'solid',borderColor: "#242424",borderWidth: "0 0 0 1px", paddingLeft: "20px"}}>
              <div style={{fontSize: '1rem',fontWeight: 'normal', marginBottom: '15px',color: 'grey', textAlign: 'center', fontStyle: userRating? "italic": "normal"}}>
                {userRating ? 'Rated' : 'Tap to rate'}
              </div>
              <div>
                {[1, 2, 3, 4, 5].map((star) => (
                  <span
                    key={star}
                    onClick={() => handleRating(star)}
                  >
                    {userRating as number >= star ? getStar('full','2rem') : getStar('empty','2rem')}
                  </span>
                ))}
              </div>
            </div>
          </div>
        }
      </>
    )
  }

  const renderOtherVersions = () => {
    return (
        <>
          {
            !store.cameraPreviewOn &&
            <div style={{padding: '0 10px'}}>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',paddingTop: "10px"}}>
                <h3 style={{color: 'gray',fontSize: "20px",fontStyle: "italic"}}>Other Versions</h3>
                {
                  songDetailData && songDetailData?.versions.length > 1 ?
                  <ViewIcon onClick={()=> {
                    console.log("clicked--")
                    navigate("/songVersions",{state: {songId: songDetailData.details.id}})
                  }}/>
                  :
                  <></>
                }
              </div>
              
              <Swiper
                  slidesPerView={'auto'}
                  spaceBetween={10}
                  freeMode={true}
                  modules={[FreeMode]}
                  className="mySwiper"
                  style={{marginLeft: "5px",marginTop: "10px",marginBottom: "20px"}}
                >
                  {songDetailData?.versions?.map((song, index) => (
                    song.active !== 1 &&
                    <SwiperSlide
                      style={{width: "147px"}}
                      key={index} onClick={() => {
                      setLoading(true);
                      getDetail(song.chord_id)
                    }}>
                      <img
                        src={songDetailData.details.image_url}
                        alt={`Song ${songDetailData.details.name}`}
                        style={{width: "147px",height: "147px", borderRadius: '20px' }}
                      />
                      <h2 className="custom-font" style={{color: lightThemeOn ? "black" : "white",textAlign: "center",fontSize: "18px",marginBottom: "0"}}>{songDetailData.details.name}</h2>
                      <h2 className="custom-font" style={{color: lightThemeOn ? "black" : "white",textAlign: "center",fontSize: "15px",margin: "0",fontWeight: "normal"}}>{song.user.name}</h2>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          }
        </>
    )
  }

  const renderLyricsText  = () => {
    return (
      <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}  style={{fontFamily: "sans-serif"}}>
        <div style={{textAlign: "center", whiteSpace: "wrap",paddingTop: "40px"}}>
                {lyrics?.map((line, lineIndex) => (
                <div key={lineIndex} style={{height: "auto",position: "relative",lineHeight: "50px"}}>
                    {isTagLine(line) ? (
                        <TagLine
                            line={line}
                            lineIndex={lineIndex}
                            chordPositions={chordPositions}
                            lyricsColor={lyricsColor}
                            chordColor={chordColor}
                            lyricsFontSize={lyricsFontsize}
                            chordFontsize={chordFontsize}
                        />
                        ) : (
                        customSplit(line).map((word, wordIndex) => (
                            <Word
                              key={wordIndex}
                              id={`word_${lineIndex}-${wordIndex}`}
                              word={word}
                              line={line}
                              chord={chordPositions[`word_${lineIndex}-${wordIndex}`]}
                              lyricsFontSize={lyricsFontsize}
                              chordFontsize={chordFontsize}
                              lyricsColor={lyricsColor}
                              chordColor={chordColor}
                            />
                        ))
                    )}
                </div>
                ))}
        </div>
    </div>
    )
  }

  const renderLyricsImg = () => {
    return (
      <>
        {
          lyricsImgFullScreen ?
          <div onClick={() => setLyricsImgFullScreen(false)} style={{ position: "fixed", top: 0, width: window.innerWidth > Number(config.screenWidth.split('px')[0]) ? config.screenWidth :  '100vw',height: "100vh", backgroundColor: "black",zIndex: 2000}}>
            <QuickPinchZoom onUpdate={onZoomUpdate} >
              <img ref={lyricsImgRef} src={songDetailData?.details?.chord_img || ""} style={{height:'100vh'}} />
            </QuickPinchZoom>
          </div>
          :
          <div onClick={() => setLyricsImgFullScreen(true)} style={{display: 'flex', justifyContent: 'center', marginTop: "10px"}}>
              <CustomImage ref={lyricsImgRef} src={songDetailData?.details.chord_img || ""} height={'60vh'} skeletonWidth={window.innerWidth > Number(config.screenWidth) ? config.screenWidth :  '100vw'} style={{height:'60vh',objectFit: 'contain'}} />
          </div>
        }
      </>
    )
  }
  

  const renderBody = () => {
    return (
      <div style={{backgroundColor: backgroundColor,marginTop: `${videoHeight - 55}px`}}>
        <div style={bodyHeaderStyle()}>
          <div style={{display: "flex",justifyContent: "space-between",alignItems: "center",margin: "0 17px",height: loading ? '30px' : 'auto'}}>
            <div style={{display: "flex",flexDirection: "column"}}>
              <h1 style={{fontSize: "15px",fontWeight: "bold",margin: "0",color: headerColor,marginBottom: "5px",fontFamily: 'Arial, sans-serif'}}>{songDetailData?.details?.name || ""}</h1>
              <h1 style={{fontSize: "15px",fontWeight: "bold",margin: "0",color: "gray",fontFamily: 'Arial, sans-serif'}}>{songDetailData?.details?.artist?.name || ""}</h1>
            </div>
          </div>
        </div>
        <div className="scrollable-y remove-scrollbar" id="lyrics-detail" style={{height: window.innerHeight - headerHeight - videoHeight}}>
          {
            lyricsImageOn ?
            renderLyricsImg()
            :
            renderLyricsText()
          }
          {renderRating()}
          {renderOtherVersions()}
        </div>
       
      </div>
    )
  }

  useEffect(() => {
    awake();
    const lyricsContainer = document.getElementById('lyrics-detail');
      let scrollIntervalId: NodeJS.Timeout | null = null;

      clearInterval(scrollIntervalId!)

      const scrollSpeeds: Record<string,{interval: number,step: number}> = {
        '1x': { interval: 40, step: 0.8},  
        '2x': { interval: 40, step: 1 },
        '3x': { interval: 40, step: 1.2 },
        '4x': { interval: 40, step: 1.4 }, 
        '5x': { interval: 40, step: 1.6 }, 
     };

      if (scroll !== '0x' && lyricsContainer) {
         const hitBottom = lyricsContainer.scrollTop + lyricsContainer.clientHeight >= lyricsContainer.scrollHeight;
         const { interval, step } = scrollSpeeds[scroll] || { interval: 40, step: 0.4 };

         scrollIntervalId = setInterval(() => {
            if(!isUserScrolling.current && !hitBottom){
              lyricsContainer.scrollBy({ top: step, behavior: 'smooth' });
            }          
         }, interval);
      }

      return () => {
         if (scrollIntervalId) {
            clearInterval(scrollIntervalId);
         }
      };
   }, [scroll]);

  const init = async () => {
    // await ads.showInterstitial();
  }

  const awake = async() => {
    const {isKeptAwake} = await KeepAwake.isKeptAwake();
    if(scroll !== '0x'){
      if(!isKeptAwake) {
        KeepAwake.keepAwake();
      }
    }else{
      if(isKeptAwake) KeepAwake.allowSleep();
    }
  }
  
  useEffect(() => {
    init();
    getDetail();
  }, []);

  return (
      <div>
          <VideoCapture lightTheme={lightThemeOn}/>
          <SettingDrawer  
            getDetail={getDetail}
            isOpen={isSettingDrawerOpen} 
            onClose={() => setIsSettingDrawerOpen(false)}
            handleFontSizeChange={handleFontSizeChange}
            handleLevelChange={handleChordLevelChange}
            lightThemeOn={lightThemeOn}
            handleThemeChange={handleThemeChange}
            lyricsImageOn={lyricsImageOn}
            isUserOwnChord={isUserOwnChord}
            handleClickEdit={handleClickEdit}
            songDetailData={songDetailData as SongDetailsType}
          />
          <Header 
             songDetailData={songDetailData}
             scroll={scroll}
             handleClickSetting={handleClickSetting}
             handleClickScroll={handleClickScroll}
             isSettingDrawerOpen={isSettingDrawerOpen}
             lightThemeOn={lightThemeOn}
             lyricsImageOn={lyricsImageOn}
             handleLyricsImageChange={handleLyricsImageChange}
           />
           {
            loading  ?
            <Skeleton width={"100%"} height={"100%"} count={20} style={{marginBottom: "10px",paddingTop: "15px"}} />
            :
            renderBody()
           }
      </div>
  )
}

export default SongDetail;


type HeaderProps = {
   songDetailData: SongDetailsType | undefined
   scroll: string,
   handleClickSetting: MouseEventHandler<HTMLButtonElement>,
   handleClickScroll: MouseEventHandler<HTMLButtonElement>,
   isSettingDrawerOpen: boolean,
   lightThemeOn: boolean,
   lyricsImageOn: boolean,
   handleLyricsImageChange: () => void;
}

const Header : React.FC<HeaderProps> = ({
  songDetailData,
  scroll = '1x',
  handleClickSetting,
  handleClickScroll,
  isSettingDrawerOpen,
  lightThemeOn,
  lyricsImageOn,
  handleLyricsImageChange
} : HeaderProps ) => {

  const {store, updateStoreFunc} = useStore();

  const {previousLocation} = useRouteTracker();

  const navigatedFromEditLyrics = previousLocation === '/dataEntry/editLyrics';

  const dynamicBackHeader = navigatedFromEditLyrics ? <BackHeader arrowColor={lightThemeOn? 'black' : 'white'} to="/library"/> : <BackHeader arrowColor={lightThemeOn? 'black' : 'white'}/>;

  const {showPlaylist, playlistOn} = useStore();

  const handleCameraChange = () => {
    updateStoreFunc('cameraPreviewOn', !store.cameraPreviewOn);
  }
  const text = store.text;
  const bothImgAndTextExist = songDetailData?.details?.chord_pro && songDetailData?.details?.chord_img;

  return (
      <div style={{display: "flex",flexDirection: "column",backgroundColor: store.cameraPreviewOn ? "transparent" : config.color.htmlColor}}>
        <div style={{display: 'flex',justifyContent: "space-between"}}>
            {dynamicBackHeader}
            <div style={{display: 'flex',alignItems: 'center',gap: "15px", marginRight: "10px"}}>
              {bothImgAndTextExist  && !store.cameraPreviewOn && <ButtonBase onClick={handleLyricsImageChange}>
                {lyricsImageOn ?  <LyricsImageOnIcon/> : <LyricsImageOffIcon/> }
              </ButtonBase>}
                {!lyricsImageOn && <ButtonBase onClick={handleCameraChange}>
                  {store.cameraPreviewOn ?  <CameraOn/> : <CameraOff/> }
                </ButtonBase>}
                <ButtonBase onClick={handleClickSetting}>
                  {isSettingDrawerOpen ? <SettingOn/> : <SettingOff/>}
                </ButtonBase>
                {!lyricsImageOn && <CustomButton onClick={handleClickScroll} active={false} style={{padding: '5px 15px',backgroundColor: "transparent",border: "1px solid gray",borderRadius: "15px", color: lightThemeOn ? "black": 'white'}}>
                  {scroll}
                </CustomButton>}
                <ButtonBase onClick={()=>  showPlaylist(songDetailData?.details.id, songDetailData?.details.chord_id, false)} >{playlistOn ? <PlaylistOnIcon style={{width: '30px',height: '30px'}}/> : <PlaylistOffIcon style={{width: '30px',height: '30px'}}/> }</ButtonBase>
            </div>
        </div>
      </div>
  )
}

interface SettingDrawerProps {
  getDetail: () => void;
  isOpen: boolean;
  onClose: () => void;
  handleFontSizeChange: (param: {id: string,value: string}) => void;
  handleLevelChange: (param: {id: string,value: string}) => void;
  lightThemeOn: boolean;
  handleThemeChange : () => void;
  lyricsImageOn: boolean;
  isUserOwnChord: boolean;
  handleClickEdit: () => void;
  songDetailData: SongDetailsType
}
const SettingDrawer: React.FC<SettingDrawerProps> = ({ isOpen, onClose, handleFontSizeChange, handleLevelChange, lightThemeOn, handleThemeChange,lyricsImageOn, isUserOwnChord, handleClickEdit, songDetailData, getDetail }) => {
  const {store} = useStore();
  const text = store.text;

  const [fontSize, setFontSize] = useState([
    { id: "0", value: '0' },
    { id: "1", value: '1' },
    { id: "2", value: '2' },
    { id: "3", value: '3' },
    { id: "4", value: '4' },
  ]);

  const [selectedLevel,setSelectedLevel] = useState({ id: "7", value: '0' });
  const [selectedFontsize,setSelectedFontsize] = useState({ id: "0", value: '0' });
  const [reportOn, setReportOn] = useState(false);
  const [selectedReport, setSelectedReport] = useState<null | number>(null);

  const reports = [
    'Wrong chords',
    'Wrong lyrics',
    "Sexual exploit",
    "Other"
  ]
  
  const [level, setLevel] = useState([
    { id: "0", value: '+7' },
    { id: "1", value: '+6' },
    { id: "2", value: '+5' },
    { id: "3", value: '+4' },
    { id: "4", value: '+3' },
    { id: "5", value: '+2' },
    { id: "6", value: '+1' },
    { id: "7", value: '0' },
    { id: "8", value: '-1' },
    { id: "9", value: '-2' },
    { id: "10", value: '-3' },
    { id: "11", value: '-4' },
    { id: "12", value: '-5' },
    { id: "13", value: '-6' },
    { id: "14", value: '-7' }
  ]);

  const renderFontAndTransposeWheel = () => {
    return (
      <div style={{display: 'flex',justifyContent: "space-evenly",marginBottom: "30px"}}>
        <div style={{display: 'flex',flexDirection: "column",justifyContent: "center",alignItems: "center", borderColor: "#242424", borderStyle: "solid", borderWidth: "2px", borderRadius: "20px"}}>
          <h3 style={{fontSize: '15px',color: "#606060", marginBottom: 0}}>{text.Transpose}</h3>
          <div data-vaul-no-drag>
            <CustomWheelPicker uniqueId='wheel-songDetail-level' data={level} handleDataChange={(v)=> setSelectedLevel(v)} selectedId={selectedLevel.id} height={150}/>
          </div>
          <CustomButton style={{margin: "0 20px 20px 20px",paddingLeft: "40px",paddingRight: "40px"}} active  onClick={() => handleLevelChange(selectedLevel)}>{text.Edit}</CustomButton>
        </div>
        <div style={{display: 'flex',flexDirection: "column",justifyContent: "center",alignItems: "center", borderColor: "#242424", borderStyle: "solid", borderWidth: "2px", borderRadius: "20px"}}>
          <h3 style={{fontSize: '15px',color: "#606060",marginBottom: 0}}>{text['Change Font']}</h3>
          <div data-vaul-no-drag>
            <CustomWheelPicker uniqueId='wheel-songDetail-fontsize' data={fontSize} handleDataChange={(v)=> setSelectedFontsize(v)} selectedId={selectedFontsize.id}  height={150}/>
          </div>
          <CustomButton style={{margin: "0 20px 20px 20px",paddingLeft: "40px",paddingRight: "40px"}} active onClick={() => handleFontSizeChange(selectedFontsize)}>{text.Edit}</CustomButton>
        </div>
      </div>
    )
  }

  const renderHeader = () => {
    const bothImgAndTextExist = songDetailData?.details?.chord_pro && songDetailData?.details?.chord_img;
    const onlyTextExist = songDetailData?.details?.chord_pro && !songDetailData?.details?.chord_img;
    const imgAlreadyExist = songDetailData?.details?.chord_img;

    let uploadedLyricsImg = "";

    const handleUploadLyricsImage = async () => {
      if(!uploadedLyricsImg) return message.info("Please upload lyrics image first!")
      const params = {
        songId: songDetailData.details.id,
        chordId: songDetailData.details.chord_id,
        chordPro: songDetailData.details.chord_pro,
        name: songDetailData.details.name,
        artistId: songDetailData.details.artist.artist_id,
        chordImg: uploadedLyricsImg
      };
      const result: any  = await api.put(endpoints.song.songEdit, params);
      if(result.code === "200") {
        getDetail();
        message.success(result.message);
      }else{
        message.error(result.message);
      }
    }

    return (
      <>
        <ButtonBase onClick={handleClickEdit} style={{display: 'flex',justifyContent: "space-between", alignItems: "center",padding: '0 10px',margin:  "20px 30px 20px 30px",borderWidth: "0 0 2px 0", borderColor: "#242424",borderStyle: 'solid'}}>
          <h3 style={{fontSize: "15px"}}>{isUserOwnChord ? text['Edit Song Lyrics']: text['Copy']}</h3>
          {isUserOwnChord ?  <Edit/> : <Copy/> }
        </ButtonBase>
        {/* {bothImgAndTextExist  && <ButtonBase onClick={handleLyricsImageChange}  style={{display: 'flex',justifyContent: "space-between", alignItems: "center",padding: '0 10px',margin: "0px 30px 20px 30px",borderWidth: "0 0 2px 0", borderColor: "#242424",borderStyle: 'solid'}}>
          <h3 style={{fontSize: "15px"}}>{text['Lyrics Image Available']}</h3>
          {lyricsImageOn ?  <EyeOnIcon/> : <EyeOffIcon/> }
        </ButtonBase>} */}
        {!lyricsImageOn && <ButtonBase onClick={handleThemeChange} style={{display: 'flex', padding: '0 10px', justifyContent: "space-between", alignItems: "center",margin:  "0 30px 20px 30px", borderWidth: "0 0 2px 0", borderColor: "#242424",borderStyle: 'solid'}}>
          <h3 style={{fontSize: "15px"}}>{text['Dark Mode']}</h3>
          {lightThemeOn ?  <LightMode/> : <DarkMode/> }
        </ButtonBase>}
        {isUserOwnChord && <ButtonBase style={{display: 'flex',justifyContent: "space-between", alignItems: "center",padding: '0 10px',margin:  "0 30px 20px 30px",borderWidth: "0 0 2px 0", borderColor: "#242424",borderStyle: 'solid'}}>
         <CustomPhotoUpload onChange={(v) => uploadedLyricsImg = v} useCropper={false} beforeUploadColor="white" afterUploadColor="#007BFF" showPreview={false} displayText={false} outlineSideText={imgAlreadyExist ? text['Edit Lyrics Image'] : text['Upload Lyrics Image']} alwaysDisplayUploadOutline={true} circle={false} style={{width: "100%",height: "60px",borderRadius: 0, margin: 0, backgroundColor: "transparent"}}/>
          <span onClick={handleUploadLyricsImage} style={{color: '#3373EF', fontSize: "15px"}}>Save</span>
        </ButtonBase>}
      </>
    )
  }

  const renderFooter = () => {
    return (
      <>
        <ButtonBase onClick={() => setReportOn(true)}  style={{display: 'flex',justifyContent: "space-between", alignItems: "center",padding: '0 10px',margin: "0 30px 20px 30px",borderWidth: "0 0 2px 0", borderColor: "#242424",borderStyle: 'solid'}}>
          <h3 style={{fontSize: "15px",color: '#FF0000'}}>{[text['Report']]}</h3>
          <ReportOnIcon/>
        </ButtonBase>
      </>
    )
  }

  const renderReportUI = () => {

    const handleReport = async () => {
      if(selectedReport === null){
        return message.info("Please select report first!")
      }
      const params = {
        songId: songDetailData.details.id,
        chordId: songDetailData.details.chord_id,
        reason: reports[selectedReport]
      }
      const result : any = await api.post(endpoints.song.report, params);
      if(result.code === "200"){
        message.success("Report sent successfully!");
        setReportOn(false);
        setSelectedReport(null);
      }else{
        message.error(result.message)
      }
    }

    const handleClickBack = () => {
      setReportOn(false);
      setSelectedReport(null);
    }

    return(
      <>
        <BackArrowIcon onClick={handleClickBack} style={{marginLeft: "30px", marginTop: "20px"}}/>
        {
          reports.map((r, i) => {
            return (
              <ButtonBase onClick={() => setSelectedReport(i)}  style={{display: 'flex',justifyContent: "start", alignItems: "center",padding: '0 10px',margin: i === 0 ? "20px 30px 20px 30px" : "0px 30px 20px 30px",borderWidth: "2px", borderColor: "#333333",borderStyle: 'solid'}}>
                {selectedReport === i ?  <ReportOnIcon/> : <ReportOffIcon/> }
                <h3 style={{fontSize: "15px",marginLeft: "10px",color: selectedReport === i ? 'red': 'white'}}>{(text as any)[r]}</h3>
              </ButtonBase>
            )
          })
        } 
        <ButtonBase onClick={handleReport}  style={{display: 'flex',justifyContent: "space-between", alignItems: "center",padding: '0 10px',margin: "0 30px 20px 30px",borderWidth: "0 0 2px 0", borderColor: "#242424",borderStyle: 'solid'}}>
          <h3 style={{fontSize: "15px",color: '#FF0000'}}>{[text['Report']]}</h3>
          <ReportOnIcon/>
        </ButtonBase>
      </>

    )
  }

  return (
    <div>
        <CustomDrawer isOpen={isOpen} onClose={onClose}>
          {
            reportOn ?
            <>
              {renderReportUI()}
            </>
            :
            <>
              {renderHeader()}
              {!lyricsImageOn && renderFontAndTransposeWheel()}
              {renderFooter()}
            </>
          }
        </CustomDrawer>
    </div>
  );
};


const isTagLine = (line: string) => {
    return line.startsWith('(');
}

const getTitleInsideParentheses = (line: string) => {
  const match = line.match(/\(([^)]+)\)/);
  return match ? match[1] : '';
};

type TagLineProps = {
    line: string,
    lineIndex: number
    chordPositions: Record<string, string> ,
    lyricsColor: string,
    chordColor: string,
    lyricsFontSize: string,
    chordFontsize: string
}
const TagLine : React.FC<TagLineProps> = ({ line, lineIndex, chordPositions, lyricsColor, chordColor, lyricsFontSize, chordFontsize }) => (
    <div className="tag-line">
      {
        customSplit(line).map((word, wordIndex) =>
          word.startsWith('(') ? (
            <TitleComponent key={wordIndex} lineIndex={lineIndex} title={getTitleInsideParentheses(line)} />
          ) : (
            <Word
              key={`word_${lineIndex}-${wordIndex}`}
              id={`word_${lineIndex}-${wordIndex}`}
              word={word}
              line={line}
              chord={chordPositions[`word_${lineIndex}-${wordIndex}`]}
              lyricsFontSize={lyricsFontSize}
              chordFontsize={chordFontsize}
              tagLine={true}
              lyricsColor={lyricsColor}
              chordColor={chordColor}
            />
          )
        )
      }
    </div>
);

interface TitleComponentProps {
  title: string;
  lineIndex: number;
}
  

const TitleComponent: React.FC<TitleComponentProps> = ({ title, lineIndex }) => {
    return (
        <div>
          <div  style={{cursor: 'pointer',display: 'flex',justifyContent: "center",alignItems: "center",color: '#3373EF', position: 'absolute', left: '0px', bottom: "5px", padding: '0 8px'}}>
              <Tag style={{marginRight: "5px"}}/>
              <div style={{textAlign: "start",userSelect: 'none'}}>
                <div>{title}</div>
                {/* <div style={{marginTop: "5px", width: "300px",height: "1px",backgroundColor: "#414141"}}></div> */}
              </div>
          </div>
        </div>
    )
};


type WordProps = {
  word: string;
  line: string;
  id: string;
  chord?: string;
  lyricsFontSize?: string;
  chordFontsize?: string;
  tagLine?: boolean;
  lyricsColor: string;
  chordColor: string
}

const Word: React.FC<WordProps> = ({ word, line, id, chord, lyricsFontSize = '16px', chordFontsize = '12px', tagLine = false , lyricsColor, chordColor }) => {
    const style = {
      display: 'inline-block',
      margin: '0px',
      position: 'relative',
      color: lyricsColor,
      borderLeft: 'none', 
      fontSize: lyricsFontSize,
      fontFamily: "Arial, sans-serif",
    } as React.CSSProperties;

    const emptySpace = tagLine ? '' : '5px'
  
    return (
      <span  style={style}>
        <div  id={`box-${id}`} className="chord-box" style={{ position: 'absolute', top: isTagLine(line)? '5px' : '-15px', left: '50%', transform: 'translateX(-50%)', width: '10px', height: '10px'}}>
          {chord && (
            <Chord key={`${id}-${chord}`} chord={chord} containerId={id} chordFontSize={chordFontsize} chordColor={chordColor}/>
          )}
        </div>
        {word === " " ? <div css={css`font-family: 'Arial, sans-serif' !important;`} style={{ width: emptySpace, color: "transparent"}}>င</div> : word}
      </span>
    );
  };

  type ChordProps = {
    chord: string;
    containerId: string,
    chordFontSize: string,
    chordColor: string
  }

  const Chord: React.FC<ChordProps> = ({ chord, containerId, chordFontSize, chordColor }) => {
  
    const style = {
      fontSize: chordFontSize,
      touchAction: 'none',
      color: chordColor,
      fontWeight: "bold",
      cursor: 'pointer',
      zIndex: 1000,
      position: "fixed",
      top: -8,
      right: 0,
      fontFamily: 'sans-serif'
    } as React.CSSProperties;
  
    return (
      <div  style={{position: 'relative'}}>
        <div style={style} className="chord" >
          {chord}
        </div>
      </div>
  );
};


