/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import React, { forwardRef, ReactNode, useState } from "react";
import { AutoComplete, Input, InputProps, InputRef } from "antd";

type CustomAutocompleteInputProps = InputProps & {
    customStyle?: React.CSSProperties,
    options?: { value: string, label: string }[],  // Adding options for autocomplete
    onSearch?: (value: string) => void,
    onAutoCompleteChange?: (value: string) => void,
    value?: string;
    onChange?: (value: string) => void;
    canSelectAutoCompleteValue?: boolean
}

const CustomAutocompleteInput = forwardRef<InputRef, CustomAutocompleteInputProps>(({ value = "",  customStyle, options = [], onSearch, onChange ,canSelectAutoCompleteValue = true, children, ...rest }, ref) =>  {

  const [inputValue, setInputValue] = useState(value);

  return (
    <div className="custom-font" css={css`
        .ant-input{
            padding: 0.6rem 1rem !important;
            margin-left: 0px;
            font-weight: 500 !important;
            font-family: inherit !important;
        }
        .ant-select-single{
            height: auto !important;
        }

        .custom-input .ant-form-item-explain-error{
            font-size: 12px;
        }

        .ant-input::placeholder{
            color: grey;
        } 

    `}>
        <Global
            styles={css`
                .ant-select-dropdown {
                    background-color: #1A1A1A !important;
                    border-color: #333333 !important;
                    color: white !important;
                    height: '52px' !important;
                }
                .ant-select-item-option-content {
                    color: white !important;
                }
                .ant-select-item-option-selected .ant-select-item-option-content{
                    color: black !important;
                }
                .ant-select-selection-item {
                    color: white !important;
                }

                .ant-empty-description{
                    color: white !important;
                }
            `}
        />
        <AutoComplete
          options={options}
          onSearch={onSearch}
          onChange={(v) => {
            if (canSelectAutoCompleteValue) {
                setInputValue(v);
                onChange?.(v);
            }
          }}
          style={{ width: "100%" }}
          value={inputValue}
        >
            <Input
                ref={ref}
                style={{backgroundColor: "#1A1A1A",borderColor: "#333333",color: 'white',fontSize: "15px",fontWeight: "bold",fontFamily: "inherit",...customStyle}}
                {...rest}
                value={inputValue}
                onChange={e => {
                    setInputValue(e.target.value)
                    onChange?.(e.target.value);
                }}
            >
                {children}
            </Input>
        </AutoComplete>
    </div>
  )
}
)

export default CustomAutocompleteInput;
