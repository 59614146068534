import React, { useEffect, useState } from 'react';

interface CustomRadioProps {
  options: {
    value: string;
    label: string;
  }[];
  value?: string;
  onChange?: (value: string) => void;
  checkedIcon: React.ReactNode;
  uncheckedIcon: React.ReactNode;
}

const CustomRadio: React.FC<CustomRadioProps> = ({ options, value, onChange, checkedIcon, uncheckedIcon }) => {
  const [selectedValue, setSelectedValue] = useState<string>(value || '');

  useEffect(() => {
    setSelectedValue(value || '');
  }, [value]);

  const handleChange = (option: string) => {
    setSelectedValue(option);
    onChange?.(option);
  };

  return (
    <div style={styles.radioGroup}>
      {options.map((option, index : number) => (
        <label key={index} style={styles.label}>
          <input
            type="radio"
            value={option.value}
            checked={selectedValue === option.value}
            onChange={() => handleChange(option.value)}
            style={styles.radio}
          />
          <span style={styles.customRadio}>
            {selectedValue === option.value ? checkedIcon : uncheckedIcon}
          </span>
          {option.label}
        </label>
      ))}
    </div>
  );
};

const styles = {
  radioGroup: {
    display: 'flex',
    justifyContent: 'space-around',
  } as React.CSSProperties,
  label: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontSize: '14px',
    marginRight: "15px"
  } as React.CSSProperties,
  radio: {
    display: 'none',
  } as React.CSSProperties,
  customRadio: {
    display: 'inline-block',
    width: '35px',
    height: '35px',
    marginRight: '1px',
    position: 'relative',
  } as React.CSSProperties,
};

export default CustomRadio;
