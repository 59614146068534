import { useRef } from 'react';

type ClickHandler = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;

const useDoubleClick = (onDoubleClick: ClickHandler, onClick?: ClickHandler) => {
  const lastClick = useRef<number | null>(null);
  const waitingClick = useRef<NodeJS.Timeout | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    if (lastClick.current && e.timeStamp - lastClick.current < 250 && waitingClick.current) {
      lastClick.current = null;
      clearTimeout(waitingClick.current);
      waitingClick.current = null;
      onDoubleClick(e);
    } else {
      lastClick.current = e.timeStamp;
      waitingClick.current = setTimeout(() => {
        waitingClick.current = null;
        if (onClick) {
          onClick(e);
        }
      }, 251);
    }
  };

  return handleClick;
};

export default useDoubleClick;
