import React, { useEffect } from 'react';

type AlertProps = {
  type: 'error' | 'info' | 'success';
  text: string;
  visible: boolean;
  onClose?: () => void;
};

const Alert: React.FC<AlertProps> = ({ type, text, visible, onClose }) => {

  const getStyles = () => {
    switch (type) {
      case 'error':
        return styles.error;
      case 'info':
        return styles.info;
      case 'success':
        return styles.success;
      default:
        return {};
    }
  };

  return (
    <div
      style={{
        ...styles.container,
        ...getStyles(),
        ...(visible ? styles.visible : styles.hidden),
      }}
    >
      <span style={styles.text}>{text}</span>
      <button style={styles.closeButton} onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

const styles = {
  container: {
    position: 'fixed' as 'fixed',
    top: '10px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    margin: '10px 0',
    borderRadius: '5px',
    color: '#fff',
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    zIndex: 1000,
    transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
    maxWidth: "100vw",
  } as React.CSSProperties,
  text: {
    // flex: 1,
  },
  closeButton: {
    background: 'none',
    border: 'none',
    color: '#fff',
    fontSize: '20px',
    cursor: 'pointer',
  },
  error: {
    backgroundColor: '#f44336',
  },
  info: {
    backgroundColor: '#2196f3',
  },
  success: {
    backgroundColor: '#4caf50',
  },
  visible: {
    opacity: 1,
    transform: 'translate(-50%, 0)',
  },
  hidden: {
    opacity: 0,
    transform: 'translate(-50%, -20px)',
  },
};

export default Alert;
