/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ButtonBase } from "@mui/material";
import { Form } from "antd";

import {ReactComponent as StarIcon} from '../../assets/icons/star.svg';
import {ReactComponent as AlbumIcon} from '../../assets/icons/album.svg';
import {ReactComponent as SongIcon} from '../../assets/icons/song.svg';
import {ReactComponent as EditOffIcon} from '../../assets/icons/edit-icon.svg';
import {ReactComponent as EditOnIcon} from '../../assets/icons/edit-on.svg';
import CustomSelect from "../CommonComponents/CustomSelect";
import CustomInput from "../CommonComponents/CustomInput";
import TagsInput from "../CommonComponents/TagsInput";
import CustomPhotoUpload from "../CommonComponents/CustomPhotoUpload";
import api from "../../api/apiClient";
import { endpoints } from "../../api/endpoints";
import { useEffect, useState } from "react";
import { AlbumType, ArtistType } from "../../types";
import { useStore } from "../../store";

type NewSongInputFormProps =  {
    handleNewSongFinish: (value: any) => void
    fromSongRequest? : {
      songId: number,
      artistId: number,
      artistName: string,
      songName: string
    }
}

export default function NewSongInputForm ({handleNewSongFinish, fromSongRequest}: NewSongInputFormProps) {

    const {store} = useStore();
    const text = store.text;
    const [artists, setArtists] = useState<{value: string,label: string}[]>([]);
    const [editWithChordEditor, setEditWithChordEditor] = useState(false);
    const [albums, setAlbums] = useState([]);
    const [composers, setComposers] = useState([]);

    const handleArtistSearch = async (v: string = "") => {
      const result: any = await api.get(endpoints.artist.search + `&keyword=${v}&current=1&limit=5`)
      if(result.code === '200'){
        const formattedArr = result.data?.by?.map((a : ArtistType) => ({value: a?.artist_id, label: a?.name}));
        setArtists(formattedArr)
      }else{
        setArtists([])
      }
    }

    const handleAlbumSearch = async (v: string = "") => {
      const result: any = await api.get(endpoints.alblum.search + `&keyword=${v}&current=1&limit=5`);
      if(result.code === '200'){
        const formattedArr = result.data?.by?.map((a : AlbumType) => ({value: a?.id, label: a?.name}));
        setAlbums(formattedArr)
      }else{
        setAlbums([])
      }
    }

    const handleComposerSearch = async (v: string = "") => {
      const result: any = await api.get(endpoints.composer.search + `&keyword=${v}&current=1&limit=5`);
      if(result.code === '200'){
        const formattedArr = result.data?.by?.map((a : ArtistType) => ({value: a?.artist_id, label: a?.name}));
        setComposers(formattedArr)
      }else{
        setComposers([])
      }
    }

    const btnStyle = {
      color: 'white', 
      border: 'none', 
      borderRadius: '20px',
      padding: '10px 54px',
      margin: '10px 0',
      cursor: 'pointer',
      fontWeight: 'bold',
    };
    const [form] = Form.useForm();

    useEffect(()=> {
      handleArtistSearch(fromSongRequest?.artistName || "");
      handleAlbumSearch();
      handleComposerSearch();
      if(fromSongRequest){
        form.setFieldValue('songId',fromSongRequest.songId);
      }
    },[])
  
    return (
      <Form
        name="basic"
        form={form}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          fontFamily: "inherit"
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={handleNewSongFinish}
        autoComplete="off"
      >
        <Form.Item
          style={{display: 'none'}}
          name="songId"
        >
        </Form.Item>
        <Form.Item
          name="songPhoto"
          wrapperCol = {{span: 24}}
          style={{padding: "",color: "white",margin: "20px 0 35px 0"}}
        >
          <CustomPhotoUpload circle={false} text={text["Add Song Cover"]}/>
        </Form.Item>
        <Form.Item
          name="songName"
          wrapperCol = {{span: 24}}
          initialValue={fromSongRequest && fromSongRequest.songName}
          rules={[
            {
              required: true,
              message: `! Song Name is required`,
            },
            {
              validator: (_, value) =>
                value && value.length > 35
                  ? Promise.reject(new Error('Singer Name is too long'))
                  : Promise.resolve(),
            },
          ]}
          style={{padding: "",color: "white",margin: "20px 0 35px 0"}}
        >
          <CustomInput placeholder={text["Song Name"]}  prefix={<SongIcon />}  />
        </Form.Item>
        <Form.Item
          name="songNameMyanmar"
          wrapperCol = {{span: 24}}
          style={{color: "white",margin: "35px 0"}}
          rules={[
            {
              validator: (_, value) =>
                value && value.length > 35
                  ? Promise.reject(new Error('Song Name is too long'))
                  : Promise.resolve(),
            },
          ]}
        >
          <CustomInput placeholder={text["Song Name(Myanmar)"]}  prefix={<SongIcon />}  />
        </Form.Item>
        <Form.Item
          name="songNameEnglish"
          wrapperCol = {{span: 24}}
          style={{color: "white",margin: "35px 0"}}
          rules={[
            {
              validator: (_, value) =>
                value && value.length > 35
                  ? Promise.reject(new Error('Song Name is too long'))
                  : Promise.resolve(),
            },
          ]}
        >
          <CustomInput placeholder={text["Song Name(English)"]}  prefix={<SongIcon />}  />
        </Form.Item>
        <Form.Item
          name="singerId"
          initialValue={fromSongRequest && fromSongRequest.artistId}
          wrapperCol = {{span: 24}}
          rules={[
            {
              required: true,
              message: `! Singer is required`,
            },
          ]}
          style={{color: "white",margin: "35px 0"}}
        >
          <CustomSelect
            size='large'
            showSearch
            filterOption={false}
            suffixIcon={<></>} 
            prefixIcon={<StarIcon />}
            placeholder={text.Singer}
            onSearch={handleArtistSearch}
            options={artists}
            onChange={(v)=> {
              const singerName = artists.filter((a : any) => a.value === v)[0]?.label;
              form.setFieldValue('singerName',singerName);
            }}
          />
        </Form.Item>
        <Form.Item
          style={{display: 'none'}}
          name="singerName"
        >
        </Form.Item>
        <Form.Item
          name="composerId"
          wrapperCol = {{span: 24}}
          style={{color: "white",margin: "35px 0"}}
        >
          <CustomSelect
            size='large'
            showSearch
            filterOption={false}
            suffixIcon={<></>} 
            prefixIcon={<StarIcon />}
            placeholder={text.Composer}
            onSearch={handleComposerSearch}
            options={composers}
          />
        </Form.Item>
        <Form.Item
          name="albumId"
          wrapperCol = {{span: 24}}
          style={{color: "white",margin: "35px 0"}}
        >
          <CustomSelect
            size='large'
            showSearch
            filterOption={false}
            suffixIcon={<></>} 
            prefixIcon={<AlbumIcon />}
            placeholder={text.Album}
            onSearch={handleAlbumSearch}
            options={albums}
          />
        </Form.Item>
        <div>
          <span style={{fontSize: "13px",color: "#5B5B5B",paddingBottom: "5px",display: "inline-block"}}>{"Remark ( Choose one or both )"}</span>
          <div style={{display: 'flex', alignItems: "center"}}>
            <Form.Item
              name="lyricsImg"
              wrapperCol = {{span: 24}}
              style={{padding: "",color: "white",margin: "10px 0 0 0", flexBasis: "45%",width: "100%"}}
              css={css`
                .ant-upload-wrapper{
                  width: 100% !important;
                }
                .ant-upload-select{
                  width: 100% !important;
                }
            ` }
            >
              <CustomPhotoUpload useCropper={false} beforeUploadColor="white" afterUploadColor="#007BFF" showPreview={false} displayText={false} outlineSideText={'Upload Lyrics Image'} alwaysDisplayUploadOutline={true} circle={false} style={{width: "100%",height: "60px",borderRadius: 0, margin: 0, backgroundColor: "transparent", border: "1px solid #333333"}}/>
            </Form.Item>
            <Form.Item
              name="editWithChordEditor"
              wrapperCol = {{span: 24}}
              style={{padding: "",color: "white",margin: "10px 0 0 0",  flexBasis: "45%",marginLeft: "10px",width: "100%"}}
            >
              <div style={{display: 'flex', alignItems: 'center',padding: "0 10px", backgroundColor: "transparent", border: "1px solid #333333",width: "100%",height: "60px"}}
                onClick={() => {
                  form.setFieldValue('editWithChordEditor',!editWithChordEditor);
                  setEditWithChordEditor(pre => !pre)
                }}
              >
                {
                  editWithChordEditor ?
                  <EditOnIcon style={{width: "30px", height: "30px", paddingRight: "20px",color: "white"}}/>
                  :
                  <EditOffIcon style={{width: "30px", height: "30px", paddingRight: "20px",color: "white"}}/>
                }
                <h3 style={{fontSize: "15px",margin: 0, color: editWithChordEditor? '#007BFF' : 'white'}}>Edit With Chord Editor</h3>
              </div>
            </Form.Item>
          </div>
        </div>
        <Form.Item
          name="tags"
          wrapperCol = {{span: 24}}
          style={{padding: "",color: "white",margin: "35px 0 0 0"}}
          // initialValue={['hello','hell']}
        >
          <TagsInput/>
        </Form.Item>
        <div style={{display: "flex",justifyContent: "center"}}>
          <ButtonBase  style={{...btnStyle,backgroundColor: '#007bff'}} type="submit">
            {text.Next}
          </ButtonBase>
        </div>
      </Form>
    )
  }