/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Form, message } from 'antd';

import {ReactComponent as StarIcon} from '../../assets/icons/star.svg';
import {ReactComponent as SongIcon} from '../../assets/icons/song.svg';
import {ReactComponent as CheckedIcon} from '../../assets/icons/check-circle.svg';
import {ReactComponent as UncheckedIcon} from '../../assets/icons/uncheck-circle.svg';
import {ReactComponent as PlusCircleIcon} from '../../assets/icons/plus-circle-on.svg';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import BackHeader from '../CommonComponents/BackHeader'
import CustomSelect from '../CommonComponents/CustomSelect';
import { useStore } from '../../store';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { ArtistType, SongType } from '../../types';
import { useEffect, useState } from 'react';
import CustomButton from '../CommonComponents/CustomButton';
import CustomAutocompleteInput from '../CommonComponents/CustomAutocompleteInput';
import CustomTextArea from '../CommonComponents/CustomTextArea';
import CustomPhotoUpload from '../CommonComponents/CustomPhotoUpload';
import CustomRadio from "../CommonComponents/CustomRadio";
import { ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function AddSongRequest() {

  const [artists, setArtists] = useState<{value: string,label: string}[]>([]);
  const [songs, setSongs] = useState<{value: string,label: string}[]>([]);
  const [loading, setLoading]  = useState(false);
  const [addArtistMode, setAddArtistMode] = useState(false);

  const [form] = Form.useForm();
  const {store} = useStore();

  const navigate = useNavigate();

  const text = store.text;

  const handleArtistSearch = async (v: string = "") => {
    const result: any = await api.get(endpoints.artist.search + `&keyword=${v}&current=1&limit=5`)
    if(result.code === '200'){
      const formattedArr = result.data?.by?.map((a : ArtistType) => ({value: a?.artist_id, label: a?.name}));
      setArtists(formattedArr)
    }else{
      setArtists([])
    }
  }

  const handleSongSearch = async (v: string = "") => {
    const result: any = await api.get(endpoints.song.search + `&keyword=${v}&current=1&limit=5`)
    if(result.code === '200'){
      const formattedArr = result.data?.by?.map((s : SongType) => ({value: s?.name, label: s?.name}));
      setSongs(formattedArr)
    }else{
      setSongs([])
    }
  };

  const handleNewSongRequestFinish = async (values: any) => {
    const {songName, singerId, description, artistName, artistImg, artistType} = values;
    if(!description) return message.info('Please fill description!');
    const existingSong = songs.findIndex(s => s.value === songName);
    if(existingSong !== -1) return message.info("Song exist!");
    setLoading(true);
    const result: any = await api.post(endpoints.songRequest.addSongRequest, {name: songName, artistId: singerId, description, artistName, artistImg, artistType});
    if(result.code === '200'){
      navigate(-1);
      message.success(result.message);
      form.resetFields();
    }else{
      message.error(result.message);
    }
    setLoading(false);
  }

  useEffect(() => {
    handleArtistSearch();
  }, [])

  return (
    <div>
      <div>
        <BackHeader/>
        <h1 style={{position: 'fixed', top: 0, left: "50%",transform: 'translate(-50%, 0)',color: "white", fontSize: "22px"}}>
          {text['Song Request']}
        </h1>
      </div>
      <Form
        name="basic"
        form={form}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          fontFamily: "inherit",
          padding: '0 15px'
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={handleNewSongRequestFinish}
        autoComplete="off"
      >
        <Form.Item
            name="songName"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: `! Song Name is required`,
              },
              {
                validator: (_, value) =>
                  value && value.length > 35
                    ? Promise.reject(new Error('Song Name is too long'))
                    : Promise.resolve(),
              },
            ]}
            style={{ padding: '', color: 'white', margin: '20px 0 35px 0' }}
          >
            <CustomAutocompleteInput
              placeholder={text['Song Name']}
              options={songs}
              onSearch={handleSongSearch}
              prefix={<SongIcon />}
              canSelectAutoCompleteValue={false}
            />
        </Form.Item>
        {!addArtistMode && <Form.Item
          name="singerId"
          wrapperCol = {{span: 24}}
          rules={[
            {
              required: true,
              message: `! Singer is required`,
            },
          ]}
          style={{color: "white",margin: "35px 0"}}
        >
          <CustomSelect
            size='large'
            showSearch
            filterOption={false}
            suffixIcon={<></>} 
            prefixIcon={<StarIcon />}
            placeholder={text.Singer}
            onSearch={handleArtistSearch}
            options={artists}
            notFoundContent={
              <div style={{display: 'flex', justifyContent: "space-between", alignItems: "center"}} onClick={()=> setAddArtistMode(true)}>
                <h3 style={{color: 'white'}}>{text['Add new singer']}</h3>
                  <PlusCircleIcon />
              </div>
            }
            onChange={(v)=> {
              const singerName = artists.filter((a : any) => a.value === v)[0]?.label;
              form.setFieldValue('singerName',singerName);
            }}
          />
        </Form.Item>}
        {
          addArtistMode &&
          <div>
            <Form.Item
              name="artistName"
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: `!Artist Name is required`,
                },
                {
                  validator: (_, value) =>
                    value && value.length > 35
                      ? Promise.reject(new Error('Artist Name is too long'))
                      : Promise.resolve(),
                },
              ]}
              style={{ padding: '', color: 'white', margin: '20px 0 35px 0' }}
            >
              <CustomAutocompleteInput
                placeholder={text['Singer Name']}
                options={artists}
                onSearch={handleArtistSearch}
                prefix={<StarIcon />}
                canSelectAutoCompleteValue={false}
              />
            </Form.Item>
            <div style={{border: "1px solid #202020",borderRadius: "20px", position: "relative"}}>
              <ButtonBase style={{width: "30px", height: "30px",position: "absolute", top: '10px', right: '10px', zIndex: 1000}}
                onClick={(e)=> {
                  e.stopPropagation();
                  form.resetFields();
                  setAddArtistMode(false)
                }}
              >
                <CloseIcon
                />
              </ButtonBase>
              <Form.Item
                name="artistImg"
                wrapperCol = {{span: 24}}
                style={{padding: "",color: "white",margin: "20px 0 0 0",width: "100%"}}
                css={css`
                  .ant-upload-wrapper{
                    width: 100% !important;
                  }
                  .ant-upload-select{
                    width: 100% !important;
                  }
              ` }
              >
                <CustomPhotoUpload beforeUploadColor="white" afterUploadColor="#007BFF" showPreview={false} displayText={false} outlineSideText={'Upload Singer Image'} alwaysDisplayUploadOutline={true} circle={false} style={{width: "100%",height: "60px",borderRadius: 0, margin: 0, backgroundColor: "transparent"}}/>
              </Form.Item>
              <Form.Item
                name="artistType"
                wrapperCol = {{span: 24}}
                rules={[
                  {
                    required: true,
                    message: `! Singer is required`,
                  },
                ]}
                style={{color: "white",margin: "10px 0 35px 0"}}
                initialValue={"male"} // value will be lower letter
              >
                <CustomRadio options={[
                    { value: 'male', label: text.Male },
                    { value: 'female', label: text.Female },
                    { value: 'group', label: text.Group },
                    { value: 'composer', label: text.Composer }
                ]} checkedIcon={<CheckedIcon width={'30px'}/>} uncheckedIcon={<UncheckedIcon width={'30px'}/>}/>
              </Form.Item>
            </div>
          </div>
        }
        <Form.Item
          name="description"
          wrapperCol = {{span: 24}}
          style={{color: "white",margin: "35px 0 18px 0"}}
          rules={[
            {
              validator: (_, value) =>
                value && value.length > 300
                  ? Promise.reject(new Error('Description is too long'))
                  : Promise.resolve(),
            },
          ]}
        >
          <CustomTextArea upperText={text["Description"]} autoSize={{ minRows: 5, maxRows: 8}}  />
        </Form.Item>
        <div style={{display: "flex",justifyContent: "center"}}>
          <CustomButton loading={loading} active={true} type='submit'>
            {text['Request']}
          </CustomButton>
        </div>
      </Form>
    </div>
  )
}
