/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { forwardRef, ReactNode } from "react";
import { Input, InputProps, InputRef } from "antd";

type CustomInputProps = InputProps & {
    customStyle?: React.CSSProperties
}

const CustomInput = forwardRef<InputRef, CustomInputProps>(({ customStyle, children, ...rest }, ref) =>  {
  return (
    <div className="custom-font" css={css`
        .ant-input{
            padding: 0.6rem 1rem !important;
            margin-left: 0px;
            font-weight: 500 !important;
            font-family: inherit !important;
        }

        .custom-input .ant-form-item-explain-error{
            font-size: 12px;
        }

        .ant-input::placeholder{
            color: grey;
        } 

    `}>
        <Input
            ref={ref}
            style={{backgroundColor: "#1A1A1A",borderColor: "#333333",color: 'white',fontSize: "15px",fontWeight: "bold",fontFamily: "inherit",...customStyle}}
            {...rest}
        >
            {children}
        </Input>
    </div>
  )
}
)

export default CustomInput;
