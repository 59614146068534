import React, { useEffect, useState } from "react";
import { ButtonBase } from "@mui/material";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { Browser } from "@capacitor/browser";
import { SplashScreen } from '@capacitor/splash-screen';
import { Device } from "@capacitor/device";

import fbLogo from '../../assets/images/fb-logo.png'
import tiktokLogo from '../../assets/images/tiktok-logo.png'
import googleLogo from '../../assets/images/google-logo.png'
import guestLogo from '../../assets/images/guest-logo.png'
import appLogo from '../../assets/images/logo-vertical.png'
import {ReactComponent as CheckIcon} from '../../assets/icons/check-circle.svg'
import {ReactComponent as UncheckIcon} from '../../assets/icons/uncheck-circle.svg'
import Loader from "../CommonComponents/Loader";
import api from "../../api/apiClient";
import { endpoints } from "../../api/endpoints";
import CustomInput from "../CommonComponents/CustomInput";
import CustomButton from "../CommonComponents/CustomButton";
import { GoogleService } from "../../socialLogin/goolge";
import { FacebookService } from "../../socialLogin/facebook";
import TikTok from "../../plugins/tiktokPlugin";
import config from "../../config";
import { generateTikTokCodeVerifier } from "../../utils";

const Login: React.FC = () => {
  const [termsAgree, setTermsAgree] = useState(false);
  const [showGuestNameInput, setShowGuestNameInput] = useState(false);
  const [guestName, setGuestName] = useState("");
  const [btnLock, setBtnLock] = useState(false);
  // const demoCode = localStorage.getItem("code") || '2fljefwje';
  const [loading, setLoading] = useState({
    value: false,
    type: ''
  });
  const GL = new GoogleService();
  const FB = new FacebookService();
  const navigate = useNavigate();

  const handleLoginClick = async (type: string) => {
    console.log("login click")
    if(!termsAgree) return message.info("Agree Terms and Conditions!")
    setBtnLock(true);
    setLoading({
        value: true,
        type
    });
    let params : any ={
        type
    };
    if(type === 'facebook'){
      params.code = await FB.login();

    }else if (type === 'tiktok'){
      const codeVerifier = generateTikTokCodeVerifier();
      const result = await TikTok.login({ clientKey: config.tiktok.clientKey, codeVerifier, redirectUri: config.tiktok.redirectUri });
      console.log('Response from tiktok:', result);
      params.code = result.authCode + " " + codeVerifier;

    }else if (type === 'google'){
      const c = await GL.login();
      if(!c) {
        setBtnLock(false);
        setLoading({
            value: false,
            type
        });
        return;
      }
      params.code = c;

    }else{
      const {identifier} = await Device.getId();
      console.log("identifier",identifier);
      params.code = identifier;
    }

    const result : any = await api.post(endpoints.user.login, params);
    setBtnLock(false);
    setLoading({
        value: false,
        type
    });

    if(result.code === '200') {
        message.success("Login Success!");
        const token = result.data;
        localStorage.setItem('token',token);
        navigate('/home')
    }else if(result.code === "400" && type === 'guest'){
        setShowGuestNameInput(true);
    }else{
        message.error(result.message);
    }
  }

  const GuestLoginClick = async () => {
    if(!guestName) return message.info('Enter Name!');
    setBtnLock(true);
    setLoading({
        value: true,
        type: 'guest'
    });
    const {identifier} = await Device.getId();
    let params = {
        type: 'guest',
        code: identifier,
        name: guestName
    }
    const result : any = await api.post(endpoints.user.login, params);
    setBtnLock(false);
    setBtnLock(true);
    setLoading({
        value: false,
        type: 'guest'
    });
    if(result.code === '200'){
        message.success("Login Success!");
        const token = result.data;
        localStorage.setItem('token',token);
        navigate('/home')
    }else{
        message.error(result.message);
    }
   }

   const openTerms = async () => {
    await Browser.open({ url: config.termsAndConditionsLink });
  };


   useEffect(()=> {
    if(localStorage.getItem('token')) {
        navigate('/home')
    }
   },[])
  return (
    <div style={styles.container}>
      <div style={styles.logoContainer}>
        <img src={appLogo} alt="Logo" style={styles.logo} />
      </div>
      {
        showGuestNameInput ? 
        <div style={{marginTop: "0rem"}}>
            <CustomInput placeholder="Enter Guest Name..." customStyle={{width: "300px"}} onChange={(e) => setGuestName(e.target.value)}/>
            <div style={{display: 'flex',justifyContent: "center",marginTop: "3rem"}}>
                <CustomButton disabled={btnLock} onClick={()=> GuestLoginClick()} active={true}>
                    {
                        loading.value ? <Loader btn /> : <span>Login</span>
                    }
                </CustomButton>
            </div>
        </div>
        : 
        <>
            <div style={styles.buttonContainer}>
                <ButtonBase disabled={btnLock} onClick={() => handleLoginClick('facebook')} style={styles.loginButton}>
                <img
                    src={fbLogo}
                    alt="Facebook"
                    style={styles.icon}
                />
                {loading.value && loading.type === 'facebook' ?
                    <Loader style={styles.buttonText} btn />
                    :
                    <span style={styles.buttonText}>Facebook</span>
                }
                </ButtonBase>
                <ButtonBase disabled={btnLock} onClick={() => handleLoginClick('tiktok')} style={styles.loginButton}>
                <img
                    src={tiktokLogo}
                    alt="TikTok"
                    style={{...styles.icon, height: "35px" }}
                />
                {loading.value && loading.type === 'tiktok' ?
                    <Loader style={styles.buttonText} btn />
                    :
                    <span style={styles.buttonText}>Tik Tok</span>
                }
                </ButtonBase>
                <ButtonBase disabled={btnLock} onClick={() => handleLoginClick('google')} style={styles.loginButton}>
                <img
                    src={googleLogo}
                    alt="Google"
                    style={styles.icon}
                />
                {loading.value && loading.type === 'google' ?
                    <Loader style={styles.buttonText} btn />
                    :
                    <span style={styles.buttonText}>Google</span>
                }
                </ButtonBase>
                <ButtonBase disabled={btnLock} onClick={() => handleLoginClick('guest')} style={styles.loginButton}>
                <img
                    src={guestLogo}
                    alt="Guest Login"
                    style={styles.icon}
                />
                {loading.value && loading.type === 'guest' ?
                    <Loader style={styles.buttonText} btn />
                    :
                    <span style={styles.buttonText}>Guest Login</span>
                }
                </ButtonBase>
            </div>
            <div style={styles.termsContainer}>
                <p style={styles.termsText}>
                I agree to the <span style={styles.termsLink} onClick={openTerms}>Terms & Conditions</span>
                </p>
                <ButtonBase style={styles.checkboxContainer} onClick={() => setTermsAgree(pre=> !pre)}>
                {
                    termsAgree ?
                    <CheckIcon/>
                    :
                    <UncheckIcon/>
                }
                </ButtonBase>
            </div>
        </>
      }
    </div>
  );
};

const styles = {
   logoContainer: {
    marginTop: "20%",
    marginBottom: "20px",
   } as React.CSSProperties,
   logo: {
    width: "200px",
    height: "200px",
    objectFit: "cover"
  } as React.CSSProperties,
  container: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "start",
    height: "90vh",
    backgroundColor: "transparent",
    color: "#fff",
  } as React.CSSProperties,
  title: {
    fontSize: "24px",
    marginBottom: "40px",
  },
  buttonContainer: {
    width: "55%",
    display: "flex",
    flexDirection: "column" as const,
    gap: "15px",
  },
  loginButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "15px",
    borderRadius: "50px",
    backgroundColor: "#fff",
    color: "#2d2d2d",
    fontSize: "15px",
    fontWeight: "bold" as const,
    cursor: "pointer",
    border: "none",
    gap: "20px",
  },
  icon: {
    width: "25px",
    height: "25px",
    position: 'absolute'
  } as React.CSSProperties,
  buttonText: {
    flex: 1,
    textAlign: "center" as const,
  },
  termsContainer: {
    marginTop: "30px",
    textAlign: "center" as const,
  },
  termsText: {
    color: "#3686FF",
    fontSize: "12px",
  },
  termsLink: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  checkboxContainer: {
    marginTop: "10px",
  },
  checkbox: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
  },
};

export default Login;
