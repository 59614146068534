import React, { useEffect, useState } from 'react'

import Footer from '../CommonComponents/Footer'
import { useStore } from '../../store'
import CustomList from '../CommonComponents/CustomList';
import BackHeader from '../CommonComponents/BackHeader';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { AlbumType, ArtistType, DataType, SongType } from '../../types';
import { useLocation } from 'react-router-dom';

export default function SongListsBySpecficId() {
  const {store} = useStore();
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState<DataType>(null);
  const [loading,setLoading] = useState(false);
  const [hasMore,setHasMore] = useState(true);
  const [page,setPage] = useState(1);

  const location = useLocation();
  const type: string = location.state?.selectedTag;
  const headerData = location.state?.headerData;

  const handleFavoriteToggle = async (songId: number,chordId: number, favorite: string) => {
    const fav = favorite === "1" ? "0" : "1";
    api.post(endpoints.song.setFav,{songId: songId,chordId: chordId, favorite: fav})
    setData((pre: any) => {
      let obj: any = {...pre};
      let arr: any = [...obj.by];
      obj.by = arr?.map((song: SongType) =>
        song.song_id === songId ? { ...song, favorite: fav } : song
      )
      return obj
    }
    );
  };

  const fetchData = async () => {
    const tag = type?.toLowerCase();
    if(page === 1) setLoading(true);
    let p = 1;
    if(searchQuery){
      p = 1
    }else{
      p = page
    }
    const filter = {
        artistId: tag === 'artist' || type === "Male" || type === "Female" ? (headerData as ArtistType).artist_id : null,
        composerId: tag === 'composer' ? (headerData as ArtistType).artist_id : null,
        albumId: (headerData as AlbumType).id
    };
    const stringifiedFilter = JSON.stringify(filter);
    const result: any = await api.get(endpoints.common.search + `?keyword=${searchQuery}&current=${p}&limit=10&type=${'title'}&filter=${stringifiedFilter}`);
    if(p === 1) setLoading(false);

    if(result.code === '200'){
      const d : DataType = result.data;
      setData((pre) => {
        let obj: any = {...pre};
        let arr : any;
        if(p > 1){
          if(pre && d) arr = [...pre?.by,...d?.by]
          obj.by = arr;
          return obj
        }else{
          return d
        }
      });
    }else{
      if(p > 1 || result.code === '404'){
        setHasMore(false);
      }else{
        setData(null);
      }
    }
  }
  
  useEffect(() => {
    if(searchQuery === ''){
      setHasMore(true);
    }
    fetchData();
  },[searchQuery, page])

  useEffect(() => {
    setPage(1);
  },[searchQuery])

  useEffect(() => {
    if(data !== null && data?.by?.length === data?.pagination?.total) {
      setHasMore(false);
    };
  },[data])

  const handleFetchMore = () => {
    setPage(pre => pre + 1);
  }

  return ( 
    <div>
      <BackHeader/>
      <div style={{padding: "0 15px"}}>
          <CustomList
            data={data}
            searchQuery={searchQuery}
            type={'Song'}
            onSearchChange={setSearchQuery}
            onFavoriteToggle={handleFavoriteToggle}
            showFavorite
            showSearchBar
            loading={loading}
            handleFetchMore={handleFetchMore}
            hasMore={hasMore}
            scrollHeight={type === 'Album' ? store.screenHeight - 230 : store.screenHeight - 200}
            artistHeader={(type === 'Artist' || type === 'Composer' || type === "Male" || type === "Female") ? true : false}
            artistHeaderData={headerData}
            albumHeader={type === 'Album' ? true : false}
            albumHeaderData={headerData}
          />
      </div>
      <Footer/>
    </div>
  )
}