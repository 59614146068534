import { useStore } from "../../store";
import CustomButton from "../CommonComponents/CustomButton";

type HeaderProps =  {
    activeHeader: string,
    handleChangeHeader: Function
}

export default function Header({activeHeader,handleChangeHeader} : HeaderProps) {
   const {store} = useStore();
   const text = store.text;
  
    return (
      <div>
        <h1 className='custom-font' style={{color: "white",fontSize: "30px"}}>{text.Adding}</h1>
        <div style={{display: "flex",justifyContent: "space-evenly"}}>
          <CustomButton style={{fontSize: "12px",padding: "10px 20px"}} active={activeHeader == 'new song' ? true : false } onClick={()=> handleChangeHeader('new song')}>
            {text["New Song"]}
          </CustomButton>
          <CustomButton style={{fontSize: "12px",padding: "10px 20px"}} active={activeHeader == 'new singer' ? true : false } onClick={()=> handleChangeHeader('new singer')}>
            {text["New Singer"]}
          </CustomButton>
          <CustomButton style={{fontSize: "12px",padding: "10px 20px"}} active={activeHeader == 'new album' ? true : false } onClick={()=> handleChangeHeader('new album')}>
            {text['New Album']}
          </CustomButton>
        </div>
      </div>
    );
  }