import { useEffect, useRef, useState } from 'react'

import Footer from '../CommonComponents/Footer'
import { useStore } from '../../store'
import CustomList from '../CommonComponents/CustomList';
import BackHeader from '../CommonComponents/BackHeader';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { DataType, SongDataType, SongType } from '../../types';
import { useLocation } from 'react-router-dom';

export default function CollectionList() {
  const {store} = useStore();
  const [data, setData] = useState<SongDataType | null>(null);
  const [loading,setLoading] = useState(false);
  const [hasMore,setHasMore] = useState(true);
  const [page,setPage] = useState(1);
  const location = useLocation();
  const collectionId = location.state.collectionId;
  const collectionName = location.state.collectionName;
  const firstMounted = useRef(true);

  const handleFavoriteToggle = async (songId: number,chordId: number, favorite: string) => {
    const fav = favorite === "1" ? "0" : "1";
    api.post(endpoints.song.setFav,{songId: songId,chordId: chordId, favorite: fav})
    setData((pre: any) => {
      let obj: any = {...pre};
      let arr: any = [...obj.by];
      obj.by = arr?.map((song: SongType) =>
        song.song_id === songId ? { ...song, favorite: fav } : song
      )
      return obj
    }
    );
  };

  const fetchData = async () => {
    if(page === 1) setLoading(true);
    let p = page;

    const result: any = await api.get(endpoints.collection.getSongsByCollection + `?collectionId=${collectionId}&current=${p}&limit=10`);
    if(p === 1) setLoading(false);
    if(result.code === '200'){
      const d : SongDataType = result.data;
      setData((pre) => {
        let obj: any = {...pre};
        let arr : any;
        if(p > 1){
          if(pre && d) arr = [...pre?.by,...d?.by]
          obj.by = arr;
          return obj
        }else{
          return d
        }
      });
    }else{
      if(p > 1){
        setHasMore(false);
      }else{
        setData(null);
      }
    }
  }
  
  useEffect(() => {
    if(firstMounted.current && data) return;
    fetchData();
  },[page])

  useEffect(() => {
    if(firstMounted.current) return;
    if(data?.by?.length === data?.pagination?.total) setHasMore(false);
  },[data])

  const handleFetchMore = () => {
    setPage(pre => pre + 1)
  }

  useEffect(()=> {
    if(firstMounted.current) firstMounted.current = false;
  },[])

  return ( 
    <div>
      <div>
        <BackHeader/>
        <h1 style={{position: 'fixed', top: 0, left: "50%",transform: 'translate(-50%, 0)',color: "white", fontSize: "22px"}}>
          {collectionName}
        </h1>
      </div>
      <div style={{padding: "0 15px"}}>
          <CustomList
            data={data}
            type={'Song'}
            onFavoriteToggle={handleFavoriteToggle}
            showFavorite
            loading={loading}
            handleFetchMore={handleFetchMore}
            hasMore={hasMore}
            scrollHeight={store.screenHeight -15}
          />
      </div>
    </div>
  )
}
