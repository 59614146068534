import React, { useState } from 'react';
import CustomWheelPicker from '../CommonComponents/CustomWheelPicker';
import CustomButton from "../CommonComponents/CustomButton";
import CustomDrawer from '../CommonComponents/CustomDrawer';
import { useStore } from '../../store';

interface TransposerDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  handleTransposerChange: (level: string ) => void;
}
const TransposerDrawer: React.FC<TransposerDrawerProps> = ({ isOpen, onClose, handleTransposerChange }) => {
  const {store} = useStore();
  const text = store.text;

  const [level, setLevel] = useState([
    { id: "0", value: '+7' },
    { id: "1", value: '+6' },
    { id: "2", value: '+5' },
    { id: "3", value: '+4' },
    { id: "4", value: '+3' },
    { id: "5", value: '+2' },
    { id: "6", value: '+1' },
    { id: "7", value: '-1' },
    { id: "8", value: '-2' },
    { id: "9", value: '-3' },
    { id: "10", value: '-4' },
    { id: "11", value: '-5' },
    { id: "12", value: '-6' },
    { id: "13", value: '-7' }
  ]);

  const [selectedLevel, setSelectedLevel] = useState<{id: string,value: string}>(level[0]);

  const handleLevelChange = (value: {id: string,value: string}) => {
    setSelectedLevel(value);
  }

  return (
    <div>
        <CustomDrawer isOpen={isOpen} onClose={onClose}>
            <div style={{width: "100%",display: 'flex',justifyContent: "center", flexDirection: "column",alignItems: 'center'}}>
              <div data-vaul-no-drag>
                <CustomWheelPicker uniqueId='wheel-level' data={level} handleDataChange={handleLevelChange}/>
              </div>
              <div style={{display: "flex",justifyContent: 'center'}}>
                <CustomButton onClick={() => handleTransposerChange(selectedLevel.value)} active style={{textAlign: 'center',paddingTop: "15px",paddingBottom: "15px",zIndex: 4000, marginBottom: "50px"}}>
                  {text['Transpose']}
                </CustomButton>
              </div>
            </div>
        </CustomDrawer>
    </div>
  );
};

export default TransposerDrawer;
