import { registerPlugin } from '@capacitor/core';

// export interface EchoPlugin {
//   echo(options: { value: string }): Promise<{ value: string }>;
// }


// const Echo = registerPlugin<EchoPlugin>('Echo');

//  export default Echo;


export interface TikTokPlugin {
    login(options: { clientKey: string; redirectUri: string; codeVerifier: string }): Promise<{ authCode?: string; grantedPermissions?: string[]; authError?: string; authErrorDescription?: string }>;
  }
  
  const TikTok = registerPlugin<TikTokPlugin>('TikTokPlugin');
  
  export default TikTok;