import React, { useEffect, useRef, useState } from 'react'

import Footer from '../CommonComponents/Footer'
import { useStore } from '../../store'
import CustomList from '../CommonComponents/CustomList';
import BackHeader from '../CommonComponents/BackHeader';
import api from '../../api/apiClient';
import { endpoints } from '../../api/endpoints';
import { DataType, SongType, storeType } from '../../types';

export default function PublicSearch() {
  const {store, updateStoreFunc} = useStore();
  const {searchQuery, selectedTag, data, loading, hasMore, page, scrollPos} = store.publicSearch;
  const firstMounted = useRef(true);

  const tags = ['Title', 'Artist', 'Album', 'Composer', 'Male', 'Female', "User"];

  const handleFavoriteToggle = async (songId: number,chordId: number, favorite: string) => {
    const fav = favorite === "1" ? "0" : "1";
    api.post(endpoints.song.setFav,{songId: songId,chordId: chordId, favorite: fav})
    let obj: any = {...data};
    let arr: any = [...obj.by];
    obj.by = arr?.map((song: SongType) =>
      song.song_id === songId ? { ...song, favorite: fav } : song
    )
    updateStoreFunc('publicSearch','data',obj)
  };

  const fetchData = async () => {
    const tag = selectedTag.toLowerCase();
    if(page === 1) updateStoreFunc('publicSearch','loading',true);
    let p = 1;
    if(searchQuery){
      p = 1
    }else{
      p = page
    }
    const result: any = await api.get(endpoints.common.search + `?keyword=${encodeURIComponent(searchQuery)}&current=${p}&limit=10&type=${tag}`);
    if(p === 1) updateStoreFunc('publicSearch','loading',false);
    if(result.code === '200'){
      updateStoreFunc(null,null,null,(pre: storeType['store']) => {
        const d : any = result.data;
        let arr: any;
        let o = {...pre};
        let a: any = {...o.publicSearch.data};
        if(p > 1){
          arr = [...a?.by,...d?.by]
          a.by = arr;
          o.publicSearch.data = a;
          return o;
        }else{
          a = d;
          o.publicSearch.data = a;
          return o;
        }
      })
    }else{
      if(p > 1){
        updateStoreFunc('publicSearch','hasMore',false);
      }else{
        updateStoreFunc('publicSearch','hasMore',false);
        updateStoreFunc('publicSearch','data',null)
      }
    }
  }
  
  useEffect(() => {
    if(firstMounted.current && data) return;
    fetchData();
  },[selectedTag, searchQuery, page])

  useEffect(() => {
    if(firstMounted.current) return;
    if(data?.by?.length === data?.pagination?.total) updateStoreFunc('publicSearch','hasMore', false);
  },[data])

  const handleFetchMore = () => {
    updateStoreFunc('publicSearch','page', page + 1);
  }

  let type : 'Song' | 'Artist' | 'Album' | 'User';
  if(selectedTag === 'Title' ){
    type = 'Song';
  }else if(selectedTag === 'Artist' || selectedTag === 'Composer' || selectedTag === 'Male' || selectedTag === 'Female'){
    type = 'Artist';
  }else if(selectedTag === 'User'){
    type = 'User';
  }else{
    type = 'Album'
  }

  const handleScroll = (event: any) => {
    const scrollTop = event.target.scrollTop;
    updateStoreFunc('publicSearch','scrollPos',scrollTop);
  }

  const handleTagChange = (v: string) => {
    if(v === selectedTag) return;
    updateStoreFunc('publicSearch','selectedTag',v);
    updateStoreFunc('publicSearch','page',1);
    updateStoreFunc('publicSearch','hasMore', true);
  }

  const handleSearchChange = (v: string) => {
    if(v.split('')[0] === '#') { // for tag search
      updateStoreFunc('publicSearch','selectedTag','Title');
      updateStoreFunc('publicSearch','hasMore', true)
    }
    if(v === ''){
      updateStoreFunc('publicSearch','hasMore',true);
    }
    updateStoreFunc('publicSearch','page', 1);
    updateStoreFunc('publicSearch','searchQuery',v);
  }

  useEffect(()=> {
    if(firstMounted.current) firstMounted.current = false;
  },[])

  return ( 
    <div>
      <BackHeader/>
      <div style={{padding: "0 15px"}}>
          <CustomList
            data={data}
            searchQuery={searchQuery}
            tags={tags}
            selectedTag={selectedTag}
            type={type}
            onSearchChange={handleSearchChange}
            onTagSelect={handleTagChange}
            onFavoriteToggle={handleFavoriteToggle}
            showFavorite
            showSearchBar
            showTagBar
            loading={loading}
            handleFetchMore={handleFetchMore}
            hasMore={hasMore}
            scrollHeight={store.screenHeight - 170}
            handleScroll={handleScroll}
            scrollPos={scrollPos}
          />
      </div>
      <Footer/>
    </div>
  )
}
