import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import config from "../config";

export class GoogleService {
    private static instance: GoogleService;

    constructor() {
        if (GoogleService.instance) {
            return GoogleService.instance;
        }
        this.init();
        GoogleService.instance = this;
    }

    private init() {
        GoogleAuth.initialize({
            clientId: config.google.clientKey,
            scopes: ['profile', 'email'],
        });
    }

    async login() {
        try{
            const response = await GoogleAuth.signIn();
            console.log("response: " ,response);
            return response.authentication.idToken;
        }catch(err){
            return null;
        }
    }

    logout(){
        GoogleAuth.signOut();
    }
}