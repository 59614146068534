import { AdLoadInfo, AdMob, AdOptions, BannerAdOptions, BannerAdPosition, BannerAdSize } from '@capacitor-community/admob';
import config from "../config";

export class AdMobService {
    private static instance: AdMobService;
    private currentNetwork = 'google';

    constructor() {
        if (AdMobService.instance) {
            return AdMobService.instance;
        }
        this.init();
        AdMobService.instance = this;
    }

    private init() {
        AdMob.initialize();
    }

    async prepareInterstitial() {
        const options: AdOptions = {
          adId: config.google.adMobInterstitialAdId,
        };
      
        try {
          const value: AdLoadInfo = await AdMob.prepareInterstitial(options);
          return value;
        } catch (error) {
          console.log('Error loading interstitial ad:', error);
        }
    }

    async showBanner() {
        try {
          const options: BannerAdOptions = {
            adId: config.google.adMobBannerAdId,
            adSize: BannerAdSize.BANNER,
            position: BannerAdPosition.BOTTOM_CENTER,
            margin: 0,
          };
          await AdMob.showBanner(options);
          return this.currentNetwork;
        } catch (error) {
          return null;
        }
    }

    async showInterstitial() {
      try {
        await AdMob.showInterstitial();
        return this.currentNetwork;
      } catch (error) {
        console.log('Error showing interstitial ad:', error);
        return null;
      }
    }

    async hideBanner(): Promise<void> {
      try {
        await AdMob.hideBanner();
      } catch (error) {
        console.log('Error hiding banner ad:', error);
      }
   }

   async resumeBanner(): Promise<void> {
    try {
      await AdMob.resumeBanner();
    } catch (error) {
      console.log('Error resumming banner ad:', error);
    }
   }

   async removeBanner(): Promise<void> {
    try {
      await AdMob.removeBanner();
    } catch (error) {
      console.log('Error removing banner ad:', error);
    }
   }
}