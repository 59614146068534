import { ButtonBase } from "@mui/material";
import { Form } from "antd";

import {ReactComponent as AlbumIcon} from '../../assets/icons/album.svg';
import CustomInput from "../CommonComponents/CustomInput";
import CustomPhotoUpload from "../CommonComponents/CustomPhotoUpload";
import CustomTextArea from "../CommonComponents/CustomTextArea";
import Loader from "../CommonComponents/Loader";
import { useStore } from "../../store";

type NewAlbumFormProps =  {
    handleNewAlbumFinish: (value: any) => void,
    loading: boolean
}

export default function NewAlbumInputForm ({handleNewAlbumFinish, loading}: NewAlbumFormProps) {
  const {store} = useStore();
  const text = store.text;


    const btnStyle = {
      color: 'white', 
      border: 'none', 
      borderRadius: '20px',
      padding: '10px 54px',
      margin: '10px 0',
      cursor: 'pointer',
      fontWeight: 'bold',
    };
  
    return (
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          fontFamily: "inherit"
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={handleNewAlbumFinish}
        autoComplete="off"
      >
         <Form.Item
          name="albumPhoto"
          wrapperCol = {{span: 24}}
          style={{padding: "",color: "white",margin: "20px 0 35px 0"}}
        >
          <CustomPhotoUpload circle={false} text={text["Add photo"]}/>
        </Form.Item>
        <Form.Item
          name="albumName"
          wrapperCol = {{span: 24}}
          rules={[
            {
              required: true,
              message: `! Album Name is required`,
            },
            {
              validator: (_, value) =>
                value && value.length > 35
                  ? Promise.reject(new Error('Album Name is too long'))
                  : Promise.resolve(),
            },
          ]}
          style={{padding: "",color: "white",margin: "20px 0 35px 0"}}
        >
          <CustomInput placeholder={text["Album Name"]}  prefix={<AlbumIcon />}  />
        </Form.Item>
        <Form.Item
          name="description"
          wrapperCol = {{span: 24}}
          style={{color: "white",margin: "35px 0 18px 0"}}
          rules={[
            {
              validator: (_, value) =>
                value && value.length > 120
                  ? Promise.reject(new Error('Description is too long'))
                  : Promise.resolve(),
            },
          ]}
        >
          <CustomTextArea autoSize={{minRows: 3, maxRows: 3}} upperText={text["Album Description"]} />
        </Form.Item>
        
        <div style={{display: "flex",justifyContent: "center"}}>
          <ButtonBase disabled={loading} style={{...btnStyle,backgroundColor: '#007bff'}} type="submit">
            {loading? <Loader loaderColor="black" btn/> : text.Save}
          </ButtonBase>
        </div>
      </Form>
    )
  }