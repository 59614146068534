import { registerPlugin } from '@capacitor/core';

export interface MetaAudiencePlugin {
    showBannerAd(options: { placementId: string, position: 'TOP' | 'CENTER' | 'BUTTON' }): Promise<void>;
    removeBannerAd(): Promise<void>;
    showInterstitialAd(options: { placementId: string }): Promise<void>;
}

const MetaAudiencePlugin = registerPlugin<MetaAudiencePlugin>('MetaAudiencePlugin');

export default MetaAudiencePlugin;